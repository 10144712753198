import PatioApi from '@/services/patio'

const getDefaultState = () => {
  return {
    dashboardCarregados: [],
    dashboardCarregadosProdutosDiaAnterior: [],
    dashboardCarregadosDestinosDiaAnterior: [],
    dashboardCarregadosProdutosDiaAtual: [],
    dashboardCarregadosDestinosDiaAtual: [],
    dashboardDescarregados: [],
    dashboardDescarregadosProdutosDiaAnterior: [],
    dashboardDescarregadosDestinosDiaAnterior: [],
    dashboardDescarregadosProdutosDiaAtual: [],
    dashboardDescarregadosDestinosDiaAtual: [],
    patios: [],
    embalagens: [],
    servicos: [],
    servicosNaoAtivos: [],
    etapas: {},
    cancelas: [],
    blacklists: [],
    motivoscancelamento: [],
    motivosCancelamentoInativos: [],
    senhasAtendimento: [],
    senhasAtendimentoCount: 0,
    tefs: [],
    carrocerias: [],
    cores: [],
    formaspagamento: [],
    marcas: [],
    modalidades: [],
    motoristas: [],
    navios: [],
    pedido: {},
    pedidos: [],
    produtos: [],
    servicosCaminhao: [],
    statusServico: [],
    tambores: [],
    tarifas: [],
    terminais: [],
    tiposControle: [],
    tiposFrete: [],
    tiposOperacao: [],
    veiculos: [],
    rfids: [],
    regioes: [],
    representantes: [],
    representantesTerritoriais: [],
    unidades: [],
    programacaoUnidade: {},
    programacoesUnidade: [],
    programacaoFornecedor: {},
    programacoesFornecedor: [],
    programacaoRegiao: {},
    programacoesRegiao: [],
    programacaoCliente: {},
    programacoesCliente: [],
    programacaoTransportadora: {},
    programacoesTransportadora: [],
    programacaoGestorTerritoriais: [],
    setores: [],
    setor: {},
    agendamento: {},
    agendamentos: [],
    agendamentosResumo: [],
    agendamentosDownload: [],
    agendamentoEtapa: [],
    agendamentoServico: [],
    convenios: [],
    conveniosNaoAtivos: [],
    suspensoes: [],
    suspensao: {},
    triagens: [],
    servicosAutorizados: [],
    dashboardFilters: {},
    dashboardAgendados: [],
    dashboardAgendadosProdutos: [],
    dashboardAgendadosDestinos: [],
    dashboardAtendidos: [],
    dashboardAtendidosProdutosHoje: [],
    dashboardAtendidosDestinosHoje: [],
    dashboardAtendidosProdutosOntem: [],
    dashboardAtendidosDestinosOntem: [],
    dashboardAtendidosProdutosNaSemana: [],
    dashboardAtendidosDestinosNaSemana: [],
    dashboardEmTransito: [],
    dashboardAprovados: [],
    dashboardMapaMovimentacaoVeiculo: [],
    dashboardTriagem: [],
    dashboardTriagemProdutos: [],
    dashboardTriagemDestinos: [],
    dashboardTriagemEtapas: [],
    dashboardTaxaChegadaSemanal: [],
    dashboardProgramadoAtendidoSemanal: [],
    selectedFilters: [],
    solicitacoes: [],
    perifericos: [],
    etapaPeriferico: {},
    etapasPeriferico: [],
    estacionamentos: [],
    itensLaudoCheckList: [],
    itensLaudoCheckListInativos: [],
    laudosItensCheckList: [],
  }
}

export const patio = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    DELETE_AGENDAMENTO(state, agendamento) {
      const index = state.agendamentos.findIndex(
        (a) => a.public_id === agendamento.public_id
      )
      state.agendamentos.splice(index, 1)
    },
    SET_DASHBOARD_FILTERS(state, payload) {
      state.dashboardFilters = Object.assign({}, payload)
    },

    // dashboard agendados
    SET_DASHBOARD_AGENDADOS(state, agendados) {
      state.dashboardAgendados = agendados
    },

    // dashboard agendados produtos
    SET_DASHBOARD_AGENDADOS_PRODUTOS(state, agendados) {
      state.dashboardAgendadosProdutos = agendados
    },

    // dashboard agendados destinos
    SET_DASHBOARD_AGENDADOS_DESTINOS(state, agendados) {
      state.dashboardAgendadosDestinos = agendados
    },

    // dashboard atendidos
    SET_DASHBOARD_ATENDIDOS(state, atendidos) {
      state.dashboardAtendidos = atendidos
    },

    // dashboard atendidos produtos hoje
    SET_DASHBOARD_ATENDIDOS_PRODUTOS_HOJE(state, atendidos) {
      state.dashboardAtendidosProdutosHoje = atendidos
    },

    // dashboard atendidos destinos hoje
    SET_DASHBOARD_ATENDIDOS_DESTINOS_HOJE(state, atendidos) {
      state.dashboardAtendidosDestinosHoje = atendidos
    },

    // dashboard atendidos produtos ontem
    SET_DASHBOARD_ATENDIDOS_PRODUTOS_ONTEM(state, atendidos) {
      state.dashboardAtendidosProdutosOntem = atendidos
    },

    // dashboard atendidos destinos ontem
    SET_DASHBOARD_ATENDIDOS_DESTINOS_ONTEM(state, atendidos) {
      state.dashboardAtendidosDestinosOntem = atendidos
    },

    // dashboard atendidos produtos na semana
    SET_DASHBOARD_ATENDIDOS_PRODUTOS_NA_SEMANA(state, atendidos) {
      state.dashboardAtendidosProdutosNaSemana = atendidos
    },

    // dashboard atendidos destinos na semana
    SET_DASHBOARD_ATENDIDOS_DESTINOS_NA_SEMANA(state, atendidos) {
      state.dashboardAtendidosDestinosNaSemana = atendidos
    },

    // dashboard em transito
    SET_DASHBOARD_EM_TRANSITO(state, em_transito) {
      state.dashboardEmTransito = em_transito
    },
    // dashboard aprovados
    SET_DASHBOARD_APROVADOS(state, aprovados) {
      state.dashboardAprovados = aprovados
    },

    // dashboard mapa movimentação de veículos
    SET_DASHBOARD_MAPA_MOVIMENTACAO_VEICULO(state, mapa_movimentacao_veiculo) {
      state.dashboardMapaMovimentacaoVeiculo = mapa_movimentacao_veiculo
    },

    // dashboard triagem > servicos executados

    SET_DASHBOARD_SERVICOS_EXECUTADOS(state, servicos_executados) {
      state.dashboardTriagem = servicos_executados
    },

    // dashboard triagem > servicos executados produtos
    SET_DASHBOARD_SERVICOS_EXECUTADOS_PRODUTOS(state, servicos_executados) {
      state.dashboardTriagemProdutos = servicos_executados
    },

    // dashboard triagem > servicos executados destinos
    SET_DASHBOARD_SERVICOS_EXECUTADOS_DESTINOS(state, servicos_executados) {
      state.dashboardTriagemDestinos = servicos_executados
    },

    // dashboard triagem > servicos executados etapas
    SET_DASHBOARD_SERVICOS_EXECUTADOS_ETAPAS(state, servicos_executados) {
      state.dashboardTriagemEtapas = servicos_executados
    },

    // dashboard triagem > taxa chegada semanal
    SET_DASHBOARD_TAXA_CHEGADA_SEMANAL(state, taxa_chegada_semanal) {
      state.dashboardTaxaChegadaSemanal = taxa_chegada_semanal
    },

    // dashboard triagem > atendido chegada semanal
    SET_DASHBOARD_PROGRAMADO_ATENDIDO_SEMANAL(
      state,
      programado_atendido_semanal
    ) {
      state.dashboardProgramadoAtendidoSemanal = programado_atendido_semanal
    },

    SET_SELECTED_FILTERS(state, payload) {
      Object.assign(state.selectedFilters, payload)
    },

    setEstacionamentos(state, estacionamentos) {
      state.estacionamentos = estacionamentos
    },

    // regiao
    SET_REGIOES(state, regioes) {
      state.regioes = regioes
    },
    ADD_REGIAO(state, regiao) {
      state.regioes.push(regiao)
    },
    UPDATE_REGIAO(state, novaRegiao) {
      let antigaRegiao = state.regioes.find(
        (regiao) => regiao.public_id === novaRegiao.public_id
      )

      Object.assign(antigaRegiao, novaRegiao)
    },
    DELETE_REGIAO(state, regiao) {
      state.regioes = state.regioes.filter(
        (r) => r.public_id !== regiao.public_id
      )
    },

    // representante
    SET_REPRESENTANTES(state, representantes) {
      state.representantes = representantes
    },
    ADD_REPRESENTANTE(state, representante) {
      state.representantes.push(representante)
    },
    DELETE_REPRESENTANTE(state, representante) {
      state.representantes = state.representantes.filter(
        (r) => r.public_id !== representante.public_id
      )
    },

    // representante territorial
    SET_REPRESENTANTES_TERRITORIAIS(state, representantes) {
      state.representantesTerritoriais = representantes
    },
    ADD_REPRESENTANTE_TERRITORIAL(state, representantes) {
      state.representantesTerritoriais.push(...representantes)
    },
    EDIT_REPRESENTANTE_TERRITORIAL(state, novoRepresentante) {
      let antigoRepresentanteTerritorial = state.representantesTerritoriais.find(
        (representanteTerritorial) =>
          representanteTerritorial.public_id === novoRepresentante.public_id
      )

      Object.assign(antigoRepresentanteTerritorial, novoRepresentante)
    },
    DELETE_REPRESENTANTE_TERRITORIAL(state, representante) {
      state.representantesTerritoriais = state.representantesTerritoriais.filter(
        (r) => r.public_id !== representante.public_id
      )
    },

    // motorista
    setMotorista(state, motorista) {
      state.motoristas = motorista
    },
    addMotorista(state, motorista) {
      state.motoristas.push(motorista)
    },
    editMotorista(state, motorista) {
      const index = state.motoristas
        .map(function (x) {
          return x.id
        })
        .indexOf(motorista.id)
      state.motoristas.splice(index, 1, motorista)
    },

    // unidade
    setUnidade(state, unidade) {
      state.unidades = unidade
    },
    addUnidade(state, unidade) {
      state.unidades.push(unidade)
    },
    editUnidade(state, unidade) {
      const index = state.unidades
        .map(function (x) {
          return x.id
        })
        .indexOf(unidade.id)
      state.unidades.splice(index, 1, unidade)
    },

    // ProgramacaoUnidade
    setProgramacoesUnidade(state, programacoesUnidade) {
      state.programacoesUnidade = programacoesUnidade
    },
    setProgramacaoUnidade(state, programacaoUnidade) {
      state.programacaoUnidade = programacaoUnidade
    },
    addProgramacaoUnidade(state, programacaoUnidade) {
      state.programacoesUnidade.push(programacaoUnidade)
    },
    editProgramacaoUnidade(state, programacaoUnidade) {
      const index = state.programacoesUnidade
        .map(function (x) {
          return x.public_id
        })
        .indexOf(programacaoUnidade.public_id)
      state.programacoesUnidade.splice(index, 1, programacaoUnidade)
    },
    deleteProgramacaoUnidade(state, public_id) {
      const index = state.programacoesUnidade
        .map(function (x) {
          return x.public_id
        })
        .indexOf(public_id)
      state.programacoesUnidade.splice(index, 1)
    },

    // ProgramacaoTransportadora
    SET_PROGRAMACAO_FORNECEDOR(state, programacaoFornecedor) {
      state.programacaoFornecedor = programacaoFornecedor
    },
    SET_PROGRAMACOES_FORNECEDOR(state, programacoesFornecedor) {
      state.programacoesFornecedor = programacoesFornecedor
    },
    ADD_PROGRAMACAO_FORNECEDOR(state, programacaoFornecedor) {
      state.programacoesFornecedor.push(programacaoFornecedor)
    },
    EDIT_PROGRAMACAO_FORNECEDOR(state, programacaoFornecedor) {
      const index = state.programacoesFornecedor
        .map(function (x) {
          return x.public_id
        })
        .indexOf(programacaoFornecedor.public_id)
      state.programacoesFornecedor.splice(index, 1, programacaoFornecedor)
    },
    DELETE_PROGRAMACAO_FORNECEDOR(state, programacaoFornecedor) {
      state.programacoesFornecedor = state.programacoesFornecedor.filter(
        (r) => r.public_id !== programacaoFornecedor.public_id
      )
    },

    // ProgramacaoRegiao
    SET_PROGRAMACAO_REGIAO(state, programacaoRegiao) {
      state.programacaoRegiao = programacaoRegiao
    },
    SET_PROGRAMACOES_REGIAO(state, programacoesRegiao) {
      state.programacoesRegiao = programacoesRegiao
    },
    ADD_PROGRAMACAO_REGIAO(state, programacaoRegiao) {
      state.programacoesRegiao.push(programacaoRegiao)
    },
    EDIT_PROGRAMACAO_REGIAO(state, programacaoRegiao) {
      const index = state.programacoesRegiao
        .map(function (x) {
          return x.public_id
        })
        .indexOf(programacaoRegiao.public_id)
      state.programacoesRegiao.splice(index, 1, programacaoRegiao)
    },
    DELETE_PROGRAMACAO_REGIAO(state, programacaoRegiao) {
      state.programacoesRegiao = state.programacoesRegiao.filter(
        (r) => r.public_id !== programacaoRegiao.public_id
      )
    },

    // ProgramacaoCliente
    SET_PROGRAMACAO_CLIENTE(state, programacaoCliente) {
      state.programacaoCliente = programacaoCliente
    },
    SET_PROGRAMACOES_CLIENTE(state, programacoesCliente) {
      state.programacoesCliente = programacoesCliente
    },
    ADD_PROGRAMACAO_CLIENTE(state, programacaoCliente) {
      state.programacoesCliente.push(programacaoCliente)
    },
    EDIT_PROGRAMACAO_CLIENTE(state, programacaoCliente) {
      const index = state.programacoesCliente
        .map(function (x) {
          return x.public_id
        })
        .indexOf(programacaoCliente.public_id)
      state.programacoesCliente.splice(index, 1, programacaoCliente)
    },
    DELETE_PROGRAMACAO_CLIENTE(state, programacaoCliente) {
      state.programacoesCliente = state.programacoesCliente.filter(
        (r) => r.public_id !== programacaoCliente.public_id
      )
    },

    // ProgramacaoGestorTerritorial
    setProgramacaoGestorTerritorial(state, programacaoGestorTerritorial) {
      state.programacaoGestorTerritoriais = programacaoGestorTerritorial
    },
    addProgramacaoGestorTerritorial(state, programacaoGestorTerritorial) {
      state.programacaoGestorTerritoriais.push(programacaoGestorTerritorial)
    },
    editProgramacaoGestorTerritorial(state, programacaoGestorTerritorial) {
      const index = state.programacaoGestorTerritoriais
        .map(function (x) {
          return x.id
        })
        .indexOf(programacaoGestorTerritorial.id)
      state.programacaoGestorTerritoriais.splice(
        index,
        1,
        programacaoGestorTerritorial
      )
    },

    // carrocerias
    setCarrocerias(state, carrocerias) {
      state.carrocerias = carrocerias
    },

    // cores
    setCores(state, cores) {
      state.cores = cores
    },

    // marcas
    setMarcas(state, marcas) {
      state.marcas = marcas
    },

    // pedidos
    SET_PEDIDO(state, pedido) {
      state.pedido = pedido
    },
    SET_PEDIDOS(state, pedidos) {
      state.pedidos = pedidos
    },
    ADD_PEDIDO(state, novosPedidos) {
      state.pedidos.results.push(...novosPedidos)
    },
    EDIT_PEDIDO(state, pedidoAtualizado) {
      const antigoPedido = state.pedidos.results.find(
        (p) => p.id === pedidoAtualizado.id
      )
      if (antigoPedido) {
        Object.assign(antigoPedido, pedidoAtualizado)
      }
    },
    DELETE_PEDIDO(state, pedido) {
      const index = state.pedidos.results.findIndex((p) => p.id === pedido.id)

      state.pedidos.results.splice(index, 1)
    },

    addProduto(state, nova) {
      state.produtos.push(nova)
    },
    editProduto(state, nova) {
      const index = state.produtos
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.produtos.splice(index, 1, nova)
    },

    // servico caminhao
    setServicosCaminhao(state, servicosCaminhao) {
      state.servicosCaminhao = servicosCaminhao
    },
    addServicoCaminhao(state, nova) {
      state.servicosCaminhao.push(nova)
    },
    editServicoCaminhao(state, nova) {
      const index = state.servicosCaminhao
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.servicosCaminhao.splice(index, 1, nova)
    },

    // status caminhao
    setStatusCaminhao(state, statusCaminhao) {
      state.statusCaminhao = statusCaminhao
    },
    addStatusCaminhao(state, nova) {
      state.statusCaminhao.push(nova)
    },
    editStatusCaminhao(state, nova) {
      const index = state.statusCaminhao
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.statusCaminhao.splice(index, 1, nova)
    },

    // status servico
    setStatusServico(state, statusServico) {
      state.statusServico = statusServico
    },
    addStatusServico(state, nova) {
      state.statusServico.push(nova)
    },
    editStatusServico(state, nova) {
      const index = state.statusServico
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.statusServico.splice(index, 1, nova)
    },

    // tambores
    setTambores(state, itens) {
      state.tambores = itens
    },
    addTambores(state, nova) {
      state.tambores.push(nova)
    },
    editTambores(state, nova) {
      const index = state.tambores
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.tambores.splice(index, 1, nova)
    },

    // tarifas
    setTarifas(state, itens) {
      state.tarifas = itens
    },
    addTarifa(state, nova) {
      state.tarifas.push(nova)
    },
    editTarifa(state, nova) {
      const index = state.tarifas
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)

      state.tarifas.splice(index, 1, nova)
    },

    deleteTarifa(state, id) {
      const index = state.tarifas
        .map(function (x) {
          return x.id
        })
        .indexOf(id)

      state.tarifas.splice(index, 1)
    },

    // terminais
    setTerminal(state, itens) {
      state.terminais = itens
    },
    addTerminal(state, nova) {
      state.terminais.push(nova)
    },
    editTerminal(state, nova) {
      const index = state.terminais
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.terminais.splice(index, 1, nova)
    },
    // tipos de controle
    setTiposControle(state, itens) {
      state.tiposControle = itens
    },
    addTipoControle(state, nova) {
      state.tiposControle.push(nova)
    },
    editTipoControle(state, nova) {
      const index = state.tiposControle
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.tiposControle.splice(index, 1, nova)
    },
    // tipos de frete
    setTiposFrete(state, itens) {
      state.tiposFrete = itens
    },
    addTipoFrete(state, nova) {
      state.tiposFrete.push(nova)
    },
    editTipoFrete(state, nova) {
      const index = state.tiposFrete
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.tiposFrete.splice(index, 1, nova)
    },

    // tipos de operações
    setTipoOperacao(state, itens) {
      state.tiposOperacao = itens
    },
    addTipoOperacao(state, nova) {
      state.tiposOperacao.push(nova)
    },
    editTipoOperacao(state, nova) {
      const index = state.tiposOperacao
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.tiposOperacao.splice(index, 1, nova)
    },

    // periférico

    setPerifericos(state, itens) {
      state.perifericos = itens
    },

    addPeriferico(state, novoPeriferico) {
      state.perifericos.push(novoPeriferico)
    },
    editPeriferico(state, nova) {
      const index = state.perifericos
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.perifericos.splice(index, 1, nova)
    },

    // etapas periféricos

    SET_ETAPA_PERIFERICO(state, etapaPeriferico) {
      state.etapaPeriferico = etapaPeriferico
    },

    ADD_ETAPA_PERIFERICO(state, etapaPeriferico) {
      state.etapasPeriferico.push(etapaPeriferico)
    },

    EDIT_ETAPA_PERIFERICO(state, etapaPeriferico) {
      const index = state.etapasPeriferico
        .map(function (x) {
          return x.public_id
        })
        .indexOf(etapaPeriferico.public_id)
      state.etapasPeriferico.splice(index, 1, etapaPeriferico)
    },

    // veiculo
    setVeiculo(state, itens) {
      state.veiculos = itens
    },
    addVeiculo(state, nova) {
      state.veiculos.push(nova)
    },
    editVeiculo(state, nova) {
      const index = state.veiculos
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.veiculos.splice(index, 1, nova)
    },

    setRFIDs(state, itens) {
      state.rfids = itens
    },

    // navios
    setNavios(state, navios) {
      state.navios = navios
    },
    addNavio(state, novo) {
      state.navios.push(novo)
    },
    editNavio(state, nova) {
      const index = state.navios
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.navios.splice(index, 1, nova)
    },

    // modalidades
    setModalidades(state, modalidades) {
      state.modalidades = modalidades
    },

    // formaspagamento
    setFormaPagamento(state, formaspagamento) {
      state.formaspagamento = formaspagamento
    },
    addFormaPagamento(state, novaFormaPagamento) {
      state.formaspagamento.push(novaFormaPagamento)
    },
    editFormaPagamento(state, novaFormaPagamento) {
      const index = state.formaspagamento
        .map(function (x) {
          return x.id
        })
        .indexOf(novaFormaPagamento.id)
      state.formaspagamento.splice(index, 1, novaFormaPagamento)
    },

    // embalagens
    setEmbalagens(state, embalagens) {
      state.embalagens = embalagens
    },
    addEmbalagem(state, novaEmbalagem) {
      state.embalagens.push(novaEmbalagem)
    },
    editEmbalagem(state, novaEmbalagem) {
      const index = state.embalagens
        .map(function (x) {
          return x.id
        })
        .indexOf(novaEmbalagem.id)
      state.embalagens.splice(index, 1, novaEmbalagem)
    },

    // servicos
    setServicos(state, servicos) {
      state.servicos = servicos
    },

    SET_SERVICOS_NAO_ATIVOS(state, servicos) {
      state.servicosNaoAtivos = servicos
    },
    addServico(state, novoServico) {
      state.servicos.push(novoServico)
    },
    editServico(state, novoServico) {
      const index = state.servicos
        .map(function (x) {
          return x.id
        })
        .indexOf(novoServico.id)
      state.servicos.splice(index, 1, novoServico)
    },
    deleteServico(state, id) {
      const index = state.servicos
        .map(function (x) {
          return x.id
        })
        .indexOf(id)
      state.servicos.splice(index, 1)
    },
    REMOVE_SERVICO(state, servico) {
      state.servicos = state.servicos.filter((c) => c.id != servico.id)
    },
    REMOVE_SERVICO_NAO_ATIVO(state, servico) {
      state.servicosNaoAtivos = state.servicosNaoAtivos.filter(
        (c) => c.id != servico.id
      )
    },
    ADD_SERVICO(state, servico) {
      state.servicos.push(servico)
    },
    ADD_SERVICO_NAO_ATIVO(state, servico) {
      state.servicosNaoAtivos.push(servico)
    },

    // etapas
    setEtapas(state, etapas) {
      state.etapas[etapas.id_servico] = etapas.etapas
    },
    addEtapa(state, novoEtapa) {
      state.etapas[novoEtapa.id_servico].push(novoEtapa.etapas)
    },
    editEtapa(state, novoEtapa) {
      const index = state.etapas[novoEtapa.id_servico]
        .map(function (x) {
          return x.id
        })
        .indexOf(novoEtapa.id)
      state.etapas[novoEtapa.id_servico].splice(index, 1, novoEtapa.etapas)
    },
    deleteEtapa(state, etapa) {
      const index = state.etapas[etapa.id_servico]
        .map(function (x) {
          return x.id
        })
        .indexOf(etapa.id)
      state.etapas[etapa.id_servico].splice(index, 1)
    },
    deleteTodasEtapas(state, id_servico) {
      if (state.etapas[id_servico]) {
        delete state.etapas[id_servico]
      }
    },

    // cancelas
    setCancelas(state, cancelas) {
      state.cancelas = cancelas
    },
    addCancela(state, novoCancela) {
      state.cancelas.push(novoCancela)
    },
    editCancela(state, novoCancela) {
      const index = state.cancelas
        .map(function (x) {
          return x.id
        })
        .indexOf(novoCancela.id)
      state.cancelas.splice(index, 1, novoCancela)
    },

    setSenhasAtendimento(state, senhasAtendimento) {
      state.senhasAtendimento = senhasAtendimento
    },

    setSenhasAtendimentoCount(state, senhasAtendimentoCount) {
      state.senhasAtendimentoCount = senhasAtendimentoCount
    },
    addSenhasAtendimento(state, novoSenhasAtendimento) {
      state.senhasAtendimento.push(novoSenhasAtendimento)
    },
    editSenhasAtendimento(state, novoSenhasAtendimento) {
      const index = state.senhasAtendimento
        .map(function (x) {
          return x.id
        })
        .indexOf(novoSenhasAtendimento.id)
      state.senhasAtendimento.splice(index, 1, novoSenhasAtendimento)
    },

    // MotivoCancelamento
    setMotivosCancelamento(state, motivosCancelamento) {
      state.motivoscancelamento = motivosCancelamento
    },
    setMotivosCancelamentoAtivos(state, motivosCancelamento) {
      state.motivosCancelamentoAtivos = motivosCancelamento
    },
    setMotivosCancelamentoInativos(state, motivosCancelamento) {
      state.motivosCancelamentoInativos = motivosCancelamento
    },
    addMotivoCancelamento(state, novoMotivoCancelamento) {
      state.motivoscancelamento.push(novoMotivoCancelamento)
    },
    editMotivoCancelamento(state, novoMotivoCancelamento) {
      const index = state.motivoscancelamento
        .map(function (x) {
          return x.public_id
        })
        .indexOf(novoMotivoCancelamento.id)
      state.motivoscancelamento.splice(index, 1, novoMotivoCancelamento)
    },

    // blacklists
    setBlacklists(state, blacklists) {
      state.blacklists = blacklists
    },
    addBlacklist(state, novoBlacklist) {
      state.blacklists.push(novoBlacklist)
    },
    editBlacklist(state, novoBlacklist) {
      const index = state.blacklists
        .map(function (x) {
          return x.id
        })
        .indexOf(novoBlacklist.id)
      state.blacklists.splice(index, 1, novoBlacklist)
    },

    // ItemLaudoCheckList
    setItensLaudoCheckList(state, itensLaudoCheckList) {
      state.itensLaudoCheckList = itensLaudoCheckList
    },
    setItensLaudoCheckListInativos(state, itensLaudoCheckList) {
      state.itensLaudoCheckListInativos = itensLaudoCheckList
    },
    addItemLaudoCheckList(state, novoItemLaudoCheckList) {
      state.itensLaudoCheckList.push(novoItemLaudoCheckList)
    },
    addLaudoItemCheckList(state, novoLaudoItemCheckList) {
      state.laudosItensCheckList.push(novoLaudoItemCheckList)
    },
    editLaudoItemCheckList(state, editLaudoItemCheckList) {
      const index = state.laudosItensCheckList
        .map(function (x) {
          return x.id
        })
        .indexOf(editLaudoItemCheckList.id)
      state.laudosItensCheckList.splice(index, 1, editLaudoItemCheckList)
    },

    // tefs
    setTefs(state, tefs) {
      state.tefs = tefs
    },
    addTefs(state, novo) {
      state.tefs.push(novo)
    },
    editTefs(state, novo) {
      const index = state.tefs
        .map(function (x) {
          return x.id
        })
        .indexOf(novo.id)
      state.tefs.splice(index, 1, novo)
    },

    // setores
    setSetor(state, setor) {
      state.setor = setor
    },
    setSetores(state, setores) {
      state.setores = setores
    },
    addSetor(state, novoSetor) {
      state.setores.push(novoSetor)
    },
    editSetor(state, novoSetor) {
      const index = state.setores
        .map(function (x) {
          return x.id
        })
        .indexOf(novoSetor.id)
      state.setores.splice(index, 1, novoSetor)
    },
    deleteSetor(state, id) {
      const index = state.setores
        .map(function (x) {
          return x.id
        })
        .indexOf(id.id)
      state.setores.splice(index, 1)
    },
    // programacoesFornecedor
    setProgramacaoFornecedor(state, programacaoFornecedor) {
      state.programacaoFornecedor = programacaoFornecedor
    },
    setProgramacoesFornecedores(state, programacoesFornecedor) {
      state.programacoesFornecedor = programacoesFornecedor
    },
    addProgramacaoFornecedor(state, novoProgramacaoFornecedor) {
      state.programacoesFornecedor.push(novoProgramacaoFornecedor)
    },
    editProgramacaoFornecedor(state, novoProgramacaoFornecedor) {
      const index = state.programacoesFornecedor
        .map(function (x) {
          return x.id
        })
        .indexOf(novoProgramacaoFornecedor.id)
      state.programacoesFornecedor.splice(index, 1, novoProgramacaoFornecedor)
    },
    deleteProgramacaoFornecedor(state, public_id) {
      const index = state.programacoesFornecedor
        .map(function (x) {
          return x.public_id
        })
        .indexOf(public_id)
      state.programacoesFornecedor.splice(index, 1)
    },
    // convenios / convênios
    SET_CONVENIOS(state, convenios) {
      state.convenios = convenios
    },
    SET_CONVENIOS_NAO_ATIVOS(state, convenios) {
      state.conveniosNaoAtivos = convenios
    },
    REMOVE_CONVENIO(state, convenio) {
      state.convenios = state.convenios.filter(
        (c) => c.public_id != convenio.public_id
      )
    },
    REMOVE_CONVENIO_NAO_ATIVO(state, convenio) {
      state.conveniosNaoAtivos = state.conveniosNaoAtivos.filter(
        (c) => c.public_id != convenio.public_id
      )
    },
    ADD_CONVENIO(state, convenio) {
      state.convenios.push(convenio)
    },
    ADD_CONVENIO_NAO_ATIVO(state, convenio) {
      state.conveniosNaoAtivos.push(convenio)
    },
    EDIT_CONVENIO(state, convenioAtualizado) {
      const convenioAntigo = state.convenios.find(
        (convenio) => convenio.public_id === convenioAtualizado.public_id
      )

      Object.assign(convenioAntigo, convenioAtualizado)
    },

    // SOLICITAÇÃO DE BAIXA
    ADD_SOLICITACAO_BAIXA(state, dados) {
      state.solicitacoes.push(dados)
    },

    SET_SOLICITACAO_BAIXA(state, dados) {
      state.solicitacoes = dados
    },

    // Agendamento
    SET_AGENDAMENTO(state, agendamento) {
      state.agendamento = agendamento
    },
    SET_AGENDAMENTOS(state, agendamentos) {
      state.agendamentos = agendamentos.results
    },
    SET_AGENDAMENTOS_RESUMO(state, agendamentos) {
      state.agendamentosResumo = agendamentos.resumo
    },
    SET_AGENDAMENTOS_DOWNLOAD(state, agendamentos) {
      state.agendamentosDownload = agendamentos
    },
    REMOVE_AGENDAMENTO(state, agendamento) {
      state.agendamentos = state.agendamentos.filter(
        (a) => a.public_id != agendamento.public_id
      )
    },
    ADD_AGENDAMENTO(state, agendamento) {
      state.agendamentos.push(agendamento)
    },
    EDIT_AGENDAMENTO(state, agendamentoAtualizado) {
      const agendamentoAtualizadoAntigo = state.agendamentos.find(
        (agendamento) =>
          agendamento.public_id === agendamentoAtualizado.public_id
      )

      // Object.assign(agendamentoAtualizado, agendamentoAtualizadoAntigo)
      Object.assign(agendamentoAtualizadoAntigo, agendamentoAtualizado)
    },

    // AgendamentoEtapa
    SET_AGENDAMENTO_ETAPA(state, itens) {
      state.agendamentoEtapa = itens
    },
    ADD_AGENDAMENTO_ETAPA(state, nova) {
      state.agendamentoEtapa.push(nova)
    },
    EDIT_AGENDAMENTO_ETAPA(state, nova) {
      const index = state.agendamentoEtapa
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.agendamentoEtapa.splice(index, 1, nova)
    },

    // AgendamentoServico
    SET_AGENDAMENTO_SERVICO(state, itens) {
      state.agendamentoServico = itens
    },
    ADD_AGENDAMENTO_SERVICO(state, nova) {
      state.agendamentoServico.push(nova)
    },
    EDIT_AGENDAMENTO_SERVICO(state, nova) {
      const index = state.agendamentoServico
        .map(function (x) {
          return x.id
        })
        .indexOf(nova.id)
      state.agendamentoServico.splice(index, 1, nova)
    },
    // Triagem
    SET_TRIANGENS(state, triagens) {
      state.triagens = triagens
    },
    // suspensoes
    SET_SUSPENSOES(state, suspensoes) {
      state.suspensoes = suspensoes
    },
    ADD_SUSPENSAO(state, suspensao) {
      state.suspensoes.push(suspensao)
    },
    EDIT_SUSPENSAO(state, suspensaoAtualizado) {
      const suspensaoAtualizadoAntigo = state.suspensoes.find(
        (suspensao) => suspensao.public_id === suspensaoAtualizado.public_id
      )
      if (suspensaoAtualizadoAntigo) {
        Object.assign(suspensaoAtualizadoAntigo, suspensaoAtualizado)
      }
    },
    DELETE_SUSPENSAO(state, suspensao) {
      state.suspensoes = state.suspensoes.filter(
        (r) => r.public_id !== suspensao.public_id
      )
    },

    SET_SERVICOS_AUTORIZADOS(state, servicos) {
      state.servicosAutorizados = servicos
    },

    // dashboard triagem > carregados
    SET_DASHBOARD_CARREGADOS(state, carregados) {
      state.dashboardCarregados = carregados
    },

    // dashboard triagem > carregados produtos dia anterior
    SET_DASHBOARD_CARREGADOS_PRODUTOS_DIA_ANTERIOR(state, carregados) {
      state.dashboardCarregadosProdutosDiaAnterior = carregados
    },

    // dashboard triagem > carregados produtos dia atual
    SET_DASHBOARD_CARREGADOS_PRODUTOS_DIA_ATUAL(state, carregados) {
      state.dashboardCarregadosDestinosDiaAtual = carregados
    },

    // dashboard triagem > carregados destinos dia anterior
    SET_DASHBOARD_CARREGADOS_DESTINOS_DIA_ANTERIOR(state, carregados) {
      state.dashboardCarregadosDestinosDiaAnterior = carregados
    },

    // dashboard triagem > carregados destinos dia atual
    SET_DASHBOARD_CARREGADOS_DESTINOS_DIA_ATUAL(state, carregados) {
      state.dashboardCarregadosDestinosDiaAtual = carregados
    },

    // dashboard triagem > descarregados
    SET_DASHBOARD_DESCARREGADOS(state, descarregados) {
      state.dashboardDescarregados = descarregados
    },

    // dashboard triagem > descarregados produtos dia anterior
    SET_DASHBOARD_DESCARREGADOS_PRODUTOS_DIA_ANTERIOR(state, descarregados) {
      state.dashboardDescarregadosProdutosDiaAnterior = descarregados
    },

    // dashboard triagem > descarregados produtos dia atual
    SET_DASHBOARD_DESCARREGADOS_PRODUTOS_DIA_ATUAL(state, descarregados) {
      state.dashboardDescarregadosProdutosDiaAtual = descarregados
    },

    // dashboard triagem > descarregados Destinos dia anterior
    SET_DASHBOARD_DESCARREGADOS_DESTINOS_DIA_ANTERIOR(state, descarregados) {
      state.dashboardDescarregadosDestinosDiaAnterior = descarregados
    },

    // dashboard triagem > descarregados Destinos dia atual
    SET_DASHBOARD_DESCARREGADOS_DESTINOS_DIA_ATUAL(state, descarregados) {
      state.dashboardDescarregadosDestinosDiaAtual = descarregados
    },
  },

  actions: {
    async cancelarAgendamento({ commit }, { tenant, agendamento }) {
      try {
        const data = await PatioApi.cancelarAgendamento(tenant, agendamento)
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async startPauseAgendamento({ commit }, { tenant, agendamento }) {
      try {
        const { data } = await PatioApi.startPauseAgendamento(
          tenant,
          agendamento
        )
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async stopPauseAgendamento({ commit }, { tenant, agendamento }) {
      try {
        const { data } = await PatioApi.stopPauseAgendamento(
          tenant,
          agendamento
        )
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getDashboardCountByParams(
      { rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const { count } = await PatioApi.getDashboardCountByParams(
          empresa,
          params
        )
        return Promise.resolve(count)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getEstacionamentos(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const estacionamentos = await PatioApi.getEstacionamentos(empresa)
        commit('setEstacionamentos', estacionamentos)
        return Promise.resolve(estacionamentos)
      } catch (err) {
        commit('setEstacionamentos', [])
        return Promise.reject(err)
      }
    },

    async getEstacionamentosPeloIdDaEmpresa({ commit }, public_id) {
      const estacionamentos = await PatioApi.getEstacionamentos(public_id)

      commit('setEstacionamentos', estacionamentos)
    },

    async addEstacionamento({ dispatch, rootState }, estacionamento) {
      if (!estacionamento) return
      const empresa = rootState.auth.empresaAtual.public_id
      const newPatio = await PatioApi.addEstacionamento(estacionamento, empresa)
      dispatch('getEstacionamentos')
      return newPatio
    },
    async editEstacionamento({ dispatch, rootState }, estacionamento) {
      if (!estacionamento) return
      const empresa = rootState.auth.empresaAtual.public_id
      await PatioApi.editEstacionamento(estacionamento, empresa)
      dispatch('getEstacionamentos')
    },

    async deleteEstacionamento({ dispatch, rootState }, estacionamento) {
      if (!estacionamento.id) return
      const empresa = rootState.auth.empresaAtual.public_id
      const isDeleted = await PatioApi.deleteEstacionamento(
        estacionamento.public_id,
        empresa
      )
      dispatch('getEstacionamentos')
      return isDeleted
    },

    // Regiao
    async getRegioes(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRegioes(empresa)
        commit('SET_REGIOES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRegioesColaborator(
      { commit, rootState },
      user = rootState.auth.user.public_id,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRegioesColaborator(empresa, user)
        commit('SET_REGIOES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async cadastrarRegiao(
      { commit, rootState },
      regiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        Object.assign(regiao, {
          proprietario: empresa.public_id,
        })

        const data = await PatioApi.cadastrarRegiao(empresa, regiao)
        commit('ADD_REGIAO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async updateRegiao(
      { commit, rootState },
      regiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.updateRegiao(empresa, regiao)
        commit('UPDATE_REGIAO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async desativarRegiao(
      { commit, rootState },
      regiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.desativarRegiao(empresa, regiao)
        commit('DELETE_REGIAO', regiao)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Agendados
    async getAgendados(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendados(empresa, params)
        commit('SET_DASHBOARD_AGENDADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Agendados Produtos
    async getAgendadosProdutos(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendadosProdutos(empresa, params)
        commit('SET_DASHBOARD_AGENDADOS_PRODUTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Agendados Destinos
    async getAgendadosDestinos(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendadosDestinos(empresa, params)
        commit('SET_DASHBOARD_AGENDADOS_DESTINOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos
    async getAtendidos(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidos(empresa, params)
        commit('SET_DASHBOARD_ATENDIDOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Produtos Hoje
    async getAtendidosProdutosHoje(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosProdutosHoje(empresa, params)
        commit('SET_DASHBOARD_ATENDIDOS_PRODUTOS_HOJE', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Destinos Hoje
    async getAtendidosDestinosHoje(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosDestinosHoje(empresa, params)
        commit('SET_DASHBOARD_ATENDIDOS_DESTINOS_HOJE', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Produtos Ontem
    async getAtendidosProdutosOntem(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosProdutosOntem(empresa, params)
        commit('SET_DASHBOARD_ATENDIDOS_PRODUTOS_ONTEM', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Destinos Ontem
    async getAtendidosDestinosOntem(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosDestinosOntem(empresa, params)
        commit('SET_DASHBOARD_ATENDIDOS_DESTINOS_ONTEM', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Produtos Na Semana
    async getAtendidosProdutosNaSemana(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosProdutosNaSemana(
          empresa,
          params
        )
        commit('SET_DASHBOARD_ATENDIDOS_PRODUTOS_NA_SEMANA', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Atendidos Destinos Na Semana
    async getAtendidosDestinosNaSemana(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAtendidosDestinosNaSemana(
          empresa,
          params
        )
        commit('SET_DASHBOARD_ATENDIDOS_DESTINOS_NA_SEMANA', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Carregados
    async getCarregados(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getCarregados(empresa, params)
        commit('SET_DASHBOARD_CARREGADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Carregados Produtos Dia Anterior
    async getCarregadosProdutosDiaAnterior(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getCarregadosProdutosDiaAnterior(
          empresa,
          params
        )
        commit('SET_DASHBOARD_CARREGADOS_PRODUTOS_DIA_ANTERIOR', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Carregados Destinos Dia Anterior
    async getCarregadosDestinosDiaAnterior(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getCarregadosDestinosDiaAnterior(
          empresa,
          params
        )
        commit('SET_DASHBOARD_CARREGADOS_DESTINOS_DIA_ANTERIOR', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Carregados Produtos Dia Atual
    async getCarregadosProdutosDiaAtual(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getCarregadosProdutosDiaAtual(
          empresa,
          params
        )
        commit('SET_DASHBOARD_CARREGADOS_PRODUTOS_DIA_ATUAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Carregados Destinos Dia Atual
    async getCarregadosDestinosDiaAtual(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getCarregadosDestinosDiaAtual(
          empresa,
          params
        )
        commit('SET_DASHBOARD_CARREGADOS_DESTINOS_DIA_ATUAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Descarregados
    async getDescarregados(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDescarregados(empresa, params)
        commit('SET_DASHBOARD_DESCARREGADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Descarregados Produtos Dia Anterior
    async getDescarregadosProdutosDiaAnterior(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDescarregadosProdutosDiaAnterior(
          empresa,
          params
        )
        commit('SET_DASHBOARD_DESCARREGADOS_PRODUTOS_DIA_ANTERIOR', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Descarregados Destinos Dia Anterior
    async getDescarregadosDestinosDiaAnterior(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDescarregadosDestinosDiaAnterior(
          empresa,
          params
        )
        commit('SET_DASHBOARD_DESCARREGADOS_DESTINOS_DIA_ANTERIOR', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Descarregados Produtos Dia Atual
    async getDescarregadosProdutosDiaAtual(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDescarregadosProdutosDiaAtual(
          empresa,
          params
        )
        commit('SET_DASHBOARD_DESCARREGADOS_PRODUTOS_DIA_ATUAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Descarregados Destinos Dia Atual
    async getDescarregadosDestinosDiaAtual(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDescarregadosDestinosDiaAtual(
          empresa,
          params
        )
        commit('SET_DASHBOARD_DESCARREGADOS_DESTINOS_DIA_ATUAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Em Transito
    async getEmTransito(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getEmTransito(empresa, params)
        commit('SET_DASHBOARD_EM_TRANSITO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Aprovados
    async getAprovados(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAprovados(empresa, params)
        commit('SET_DASHBOARD_APROVADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // Dashboard Detalhes Aprovados
    async getDetalhesAprovados(
      { rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getDetalhesAprovados(empresa, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Mapa Movimentação de Veículos
    async getMapaMovimentacaoVeiculo(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getMapaMovimentacaoVeiculo(empresa, params)
        commit('SET_DASHBOARD_MAPA_MOVIMENTACAO_VEICULO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Servicos Executados
    async getServicosExecutados(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getServicosExecutados(empresa, params)
        commit('SET_DASHBOARD_SERVICOS_EXECUTADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Servicos Executados Produtos
    async getServicosExecutadosProdutos(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getServicosExecutadosProdutos(
          empresa,
          params
        )
        commit('SET_DASHBOARD_SERVICOS_EXECUTADOS_PRODUTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Servicos Executados Destinos
    async getServicosExecutadosDestinos(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getServicosExecutadosDestinos(
          empresa,
          params
        )
        commit('SET_DASHBOARD_SERVICOS_EXECUTADOS_DESTINOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Servicos Executados Etapas
    async getServicosExecutadosEtapas(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getServicosExecutadosEtapas(empresa, params)
        commit('SET_DASHBOARD_SERVICOS_EXECUTADOS_ETAPAS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Dashboard Taxa Chegada Semanal
    async getTaxaChegadaSemanal(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getTaxaChegadaSemanal(empresa, params)
        commit('SET_DASHBOARD_TAXA_CHEGADA_SEMANAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // Dashboard Taxa Chegada Semanal
    async getProgramadoAtendidoSemanal(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramadoAtendidoSemanal(
          empresa,
          params
        )
        commit('SET_DASHBOARD_PROGRAMADO_ATENDIDO_SEMANAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Representante
    async getRepresentantes(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRepresentantes(empresa)
        commit('SET_REPRESENTANTES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRepresentante(
      { rootState },
      publicID,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRepresentante(publicID, empresa)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRepresentanteByCPF(
      { rootState },
      cpf,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRepresentanteByCPF(empresa, cpf)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async cadastrarRepresentante(
      { commit, rootState },
      representante,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.cadastrarRepresentante(
          empresa,
          representante
        )
        commit('ADD_REPRESENTANTE', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async removerRepresentante(
      { commit, rootState },
      representante,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerRepresentante(empresa, representante)
        commit('DELETE_REPRESENTANTE', representante)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Representante Territorial
    async getRepresentantesTerritoriais(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRepresentantesTerritoriais(empresa)
        commit('SET_REPRESENTANTES_TERRITORIAIS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getRepresentantesTerritoriaisByRepresentantePublicID(
      { commit, rootState },
      representantePublicID,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRepresentantesTerritoriaisByRepresentantePublicID(
          representantePublicID,
          empresa
        )
        commit('SET_REPRESENTANTES_TERRITORIAIS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async cadastrarRepresentanteTerritorial(
      { commit, rootState },
      representante,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.cadastrarRepresentanteTerritorial(
          empresa,
          representante
        )
        commit('ADD_REPRESENTANTE_TERRITORIAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async atualizarRepresentanteTerritorial(
      { commit, rootState },
      representante,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.atualizarRepresentanteTerritorial(
          empresa,
          representante
        )
        commit('EDIT_REPRESENTANTE_TERRITORIAL', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async removerRepresentanteTerritorial(
      { commit, rootState },
      representante,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerRepresentanteTerritorial(empresa, representante)
        commit('DELETE_REPRESENTANTE_TERRITORIAL', representante)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Motorista
    async getMotorista({ commit }, params) {
      try {
        const itens = await PatioApi.getMotorista(params)
        commit('setMotorista', itens)
        return Promise.resolve(itens)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async addMotorista({ dispatch }, novo) {
      await PatioApi.addMotorista(novo)
      dispatch('getMotorista')
    },
    async editMotorista({ dispatch }, motorista) {
      await PatioApi.editMotorista(motorista)
      dispatch('getMotorista')
    },
    async updateDocumentosMotorista({ dispatch }, payload) {
      await PatioApi.updateDocumentosMotorista(
        payload.motorista,
        payload.documentos
      )
      dispatch('getMotorista')
    },
    async updateFotoMotorista({ dispatch }, payload) {
      await PatioApi.updateFotoMotorista(payload.motorista, payload.foto)
      dispatch('getMotorista')
    },
    async updateDocumentosVeiculo({ dispatch }, payload) {
      await PatioApi.updateDocumentosVeiculo(
        payload.veiculo,
        payload.documentos
      )
      dispatch('getVeiculo')
    },

    // Modalidade

    async addModalidade({ dispatch }, nova) {
      await PatioApi.addModalidade(nova)
      dispatch('getModalidades')
    },

    async getModalidades({ commit }) {
      try {
        const modalidades = await PatioApi.getModalidades()
        commit('setModalidades', modalidades)
        return Promise.resolve(modalidades)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getModalidadesImplementos({ commit }) {
      try {
        const modalidades = await PatioApi.getModalidades({ tipo: 2 })
        commit('setModalidades', modalidades)
        return Promise.resolve(modalidades)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async editModalidade({ dispatch }, novo) {
      await PatioApi.editModalidade(novo)
      dispatch('getModalidades')
    },

    async deleteModalidade({ dispatch }, id) {
      await PatioApi.deleteModalidade(id)
      dispatch('getModalidades')
    },

    // Carroceria

    async getCarrocerias({ commit }) {
      try {
        const carrocerias = await PatioApi.getCarrocerias()
        commit('setCarrocerias', carrocerias)
        return Promise.resolve(carrocerias)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addCarroceria({ dispatch }, novo) {
      await PatioApi.addCarroceria(novo)
      dispatch('getCarrocerias')
    },

    async editCarroceria({ dispatch }, novo) {
      await PatioApi.editCarroceria(novo)
      dispatch('getCarrocerias')
    },

    async deleteCarroceria({ dispatch }, id) {
      await PatioApi.deleteCarroceria(id)
      dispatch('getCarrocerias')
    },

    // Cores

    async getCores({ commit }) {
      try {
        const cores = await PatioApi.getCores()
        commit('setCores', cores)
        return Promise.resolve(cores)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addCor({ dispatch }, novo) {
      await PatioApi.addCor(novo)
      dispatch('getCores')
    },

    async editCor({ dispatch }, novo) {
      await PatioApi.editCor(novo)
      dispatch('getCores')
    },

    async deleteCor({ dispatch }, id) {
      await PatioApi.deleteCor(id)
      dispatch('getCores')
    },

    // Marcas

    async getMarcas({ commit }) {
      try {
        const marcas = await PatioApi.getMarcas()
        commit('setMarcas', marcas)
        return Promise.resolve(marcas)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addMarca({ dispatch }, novo) {
      await PatioApi.addMarca(novo)
      dispatch('getMarcas')
    },

    async editMarca({ dispatch }, novo) {
      await PatioApi.editMarca(novo)
      dispatch('getMarcas')
    },

    async deleteMarca({ dispatch }, id) {
      await PatioApi.deleteMarca(id)
      dispatch('getMarcas')
    },

    // Unidade
    async getUnidade({ commit }) {
      const itens = await PatioApi.getUnidade()
      commit('setUnidade', itens)
    },
    async addUnidade({ dispatch }, novo) {
      await PatioApi.addUnidade(novo)
      dispatch('getUnidade')
    },
    async editUnidade({ dispatch }, novo) {
      await PatioApi.editUnidade(novo)
      dispatch('getUnidade')
    },

    //  ProgramacoesUnidade ProgramacaoUnidade

    async getProgramacaoUnidade(
      { commit, rootState },
      { programacaoUnidade_public_id }
    ) {
      const empresa = rootState.auth.empresaAtual.public_id
      try {
        const programacaoUnidade = await PatioApi.getProgramacaoUnidade(
          programacaoUnidade_public_id,
          empresa
        )
        commit('setProgramacaoUnidade', programacaoUnidade)
        return Promise.resolve(programacaoUnidade)
      } catch (error) {
        commit('setProgramacaoUnidade', {})
        return Promise.reject(error)
      }
    },
    async getProgramacoesUnidade({ commit, rootState }, params) {
      const empresa = rootState.auth.empresaAtual
      try {
        const programacoesUnidade = await PatioApi.getProgramacoesUnidade(
          empresa,
          params
        )
        commit('setProgramacoesUnidade', programacoesUnidade)
        return Promise.resolve(programacoesUnidade)
      } catch (error) {
        commit('setProgramacoesUnidade', [])
        return Promise.reject(error)
      }
    },
    async addProgramacaoUnidade({ commit, rootState }, novoProgramacaoUnidade) {
      try {
        const empresa = rootState.auth.empresaAtual.public_id
        const programacaoUnidadeAdicionada = await PatioApi.addProgramacaoUnidade(
          novoProgramacaoUnidade,
          empresa
        )
        commit('addProgramacaoUnidade', programacaoUnidadeAdicionada)
        return Promise.resolve(programacaoUnidadeAdicionada)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async editProgramacaoUnidade({ commit, rootState }, novoValor) {
      try {
        const empresa = rootState.auth.empresaAtual.public_id
        const programacaoUnidadeAlterada = await PatioApi.editProgramacaoUnidade(
          novoValor,
          empresa
        )
        commit('editProgramacaoUnidade', programacaoUnidadeAlterada)
        return Promise.resolve(programacaoUnidadeAlterada)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteProgramacaoUnidade({ commit }, programacaoUnidade) {
      try {
        await PatioApi.deleteProgramacaoUnidade(programacaoUnidade)
        commit('deleteProgramacaoUnidade', programacaoUnidade)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },
    //  ProgramacoesFornecedor ProgramacaoFornecedor
    async getProgramacaoFornecedor(
      { commit, rootState },
      { programacaoFornecedor_public_id },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacaoFornecedor(
          { public_id: programacaoFornecedor_public_id },
          empresa
        )
        commit('SET_PROGRAMACAO_FORNECEDOR', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACAO_FORNECEDOR', {})
        return Promise.reject(error)
      }
    },
    async getProgramacoesFornecedor(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacoesFornecedor(empresa, params)
        commit('SET_PROGRAMACOES_FORNECEDOR', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACOES_FORNECEDOR', [])
        return Promise.reject(error)
      }
    },
    async addProgramacaoFornecedor(
      { commit, rootState },
      programacaoFornecedor,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addProgramacaoFornecedor(
          programacaoFornecedor,
          empresa
        )
        commit('ADD_PROGRAMACAO_FORNECEDOR', data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async editProgramacaoFornecedor(
      { commit, rootState },
      programacaoFornecedor,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editProgramacaoFornecedor(
          programacaoFornecedor,
          empresa
        )
        commit('EDIT_PROGRAMACAO_FORNECEDOR', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async deleteProgramacaoFornecedor(
      { commit, rootState },
      programacaoFornecedor,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.deleteProgramacaoFornecedor(
          programacaoFornecedor,
          empresa
        )
        commit('DELETE_PROGRAMACAO_FORNECEDOR', programacaoFornecedor)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    //  ProgramacoesRegiao ProgramacaoRegiao
    async getProgramacaoRegiao(
      { commit, rootState },
      { programacaoRegiao_public_id },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacaoRegiao(
          { public_id: programacaoRegiao_public_id },
          empresa
        )
        commit('SET_PROGRAMACAO_REGIAO', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACAO_REGIAO', {})
        return Promise.reject(error)
      }
    },
    async getProgramacoesRegiao(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacoesRegiao(empresa, params)
        commit('SET_PROGRAMACOES_REGIAO', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACOES_REGIAO', [])
        return Promise.reject(error)
      }
    },
    async getProgramacoesRegiaoAgendavel(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacoesRegiaoAgendavel(
          empresa,
          params
        )
        commit('SET_PROGRAMACOES_REGIAO', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACOES_REGIAO', [])
        return Promise.reject(error)
      }
    },
    async addProgramacaoRegiao(
      { commit, rootState },
      programacaoRegiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addProgramacaoRegiao(
          programacaoRegiao,
          empresa
        )
        commit('ADD_PROGRAMACAO_REGIAO', data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async editProgramacaoRegiao(
      { commit, rootState },
      programacaoRegiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editProgramacaoRegiao(
          programacaoRegiao,
          empresa
        )
        commit('EDIT_PROGRAMACAO_REGIAO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async deleteProgramacaoRegiao(
      { commit, rootState },
      programacaoRegiao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.deleteProgramacaoRegiao(programacaoRegiao, empresa)
        commit('DELETE_PROGRAMACAO_REGIAO', programacaoRegiao)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    //  ProgramacoesCliente ProgramacaoCliente
    async getProgramacaoCliente(
      { commit, rootState },
      { programacaoCliente_public_id },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacaoCliente(
          { public_id: programacaoCliente_public_id },
          empresa
        )
        commit('SET_PROGRAMACAO_CLIENTE', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACAO_CLIENTE', {})
        return Promise.reject(error)
      }
    },
    async getProgramacoesCliente(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacoesCliente(empresa, params)
        commit('SET_PROGRAMACOES_CLIENTE', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACOES_CLIENTE', [])
        return Promise.reject(error)
      }
    },
    async getProgramacoesClienteAgendavel(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacoesClienteAgendavel(
          empresa,
          params
        )
        commit('SET_PROGRAMACOES_CLIENTE', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_PROGRAMACOES_CLIENTE', [])
        return Promise.reject(error)
      }
    },
    async addProgramacaoCliente(
      { commit, rootState },
      programacaoCliente,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addProgramacaoCliente(
          programacaoCliente,
          empresa
        )
        commit('ADD_PROGRAMACAO_CLIENTE', data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async editProgramacaoCliente(
      { commit, rootState },
      programacaoCliente,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editProgramacaoCliente(
          programacaoCliente,
          empresa
        )
        commit('EDIT_PROGRAMACAO_CLIENTE', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async deleteProgramacaoCliente(
      { commit, rootState },
      programacaoCliente,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.deleteProgramacaoCliente(programacaoCliente, empresa)
        commit('DELETE_PROGRAMACAO_CLIENTE', programacaoCliente)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // ProgramacaoGestorTerritorial
    async getProgramacaoGestorTerritorial({ commit }) {
      const itens = await PatioApi.getProgramacaoGestorTerritorial()
      commit('setProgramacaoGestorTerritorial', itens)
    },
    async addProgramacaoGestorTerritorial({ dispatch }, novo) {
      await PatioApi.addProgramacaoGestorTerritorial(novo)
      dispatch('getProgramacaoGestorTerritorial')
    },
    async editProgramacaoGestorTerritorial({ dispatch }, novo) {
      await PatioApi.editProgramacaoGestorTerritorial(novo)
      dispatch('getProgramacaoGestorTerritorial')
    },

    // tipo de controle
    async getTiposControle({ commit }) {
      const itens = await PatioApi.getTiposControle()
      commit('setTiposControle', itens)
    },

    // tipo de frete
    async getTiposFrete({ commit }) {
      const itens = await PatioApi.getTiposFrete()
      commit('setTiposFrete', itens)
    },

    // perifericos

    async getPeriferico({ commit }, params) {
      try {
        const itens = await PatioApi.getPerifericos(params)
        commit('setPerifericos', itens)
        return Promise.resolve(itens)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async addPeriferico({ dispatch }, periferico) {
      try {
        periferico = await PatioApi.addPeriferico(periferico)
        dispatch('getPeriferico', { tenant: periferico.proprietario })
        return Promise.resolve(periferico)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async editPeriferico({ dispatch }, periferico) {
      try {
        periferico = await PatioApi.editPeriferico(periferico)
        dispatch('getPeriferico', { tenant: periferico.proprietario })
        return Promise.resolve(periferico)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deletePeriferico({ dispatch }, periferico) {
      if (periferico.id && periferico.proprietario) {
        await PatioApi.deletePeriferico(periferico.id)
        dispatch('getPeriferico', { tenant: periferico.proprietario })
      }
    },

    // etapas perifericos

    async getEtapaPeriferico({ commit }, { periferico_public_id }) {
      try {
        const data = await PatioApi.getEtapaPeriferico({
          public_id: periferico_public_id,
        })
        commit('SET_ETAPA_PERIFERICO', data)
        return Promise.resolve(data)
      } catch (error) {
        commit('SET_ETAPA_PERIFERICO', {})
        return Promise.reject(error)
      }
    },

    async addEtapaPeriferico({ commit }, etapaPeriferico) {
      try {
        const data = await PatioApi.addEtapaPeriferico(etapaPeriferico)
        commit('ADD_ETAPA_PERIFERICO', data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async editEtapaPeriferico({ commit }, etapaPeriferico) {
      try {
        const data = await PatioApi.editEtapaPeriferico(etapaPeriferico)
        commit('EDIT_ETAPA_PERIFERICO', data)
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deleteEtapaPeriferico(etapaPeriferico) {
      try {
        await PatioApi.deleteEtapaPeriferico(etapaPeriferico.id)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // tipo de operação
    async getTipoOperacao({ commit }) {
      const itens = await PatioApi.getTipoOperacao()
      commit('setTipoOperacao', itens)
    },

    async getVeiculo({ commit }, params) {
      try {
        const itens = await PatioApi.getVeiculos(params)
        commit('setVeiculo', itens)
        return Promise.resolve(itens)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addVeiculo({ dispatch }, veiculo) {
      try {
        if (veiculo.proprietario) {
          veiculo = await PatioApi.addVeiculo(veiculo)
          dispatch('getVeiculo', { tenant: veiculo.proprietario })
          return Promise.resolve(veiculo)
        }
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async editVeiculo(_, veiculo) {
      try {
        if (veiculo.proprietario) {
          veiculo = await PatioApi.editVeiculo(veiculo)
          return Promise.resolve(veiculo)
        }
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async deleteVeiculo({ dispatch }, dados) {
      if (dados.id && dados.proprietario) {
        await PatioApi.deleteVeiculo(dados.id)
        dispatch('getVeiculo', { tenant: dados.proprietario.public_id })
      }
    },

    async getRFIDs({ commit }, { veiculo, params }) {
      try {
        const itens = await PatioApi.getRFIDs(veiculo, params)
        commit('setRFIDs', itens)
        return Promise.resolve(itens)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addRFID(_, { veiculo, rfid }) {
      try {
        rfid = await PatioApi.addRFID(veiculo, rfid)
        return Promise.resolve(rfid)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async editRFID(_, { veiculo, rfid }) {
      try {
        rfid = await PatioApi.editRFID(veiculo, rfid)
        return Promise.resolve(rfid)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async deleteRFID(_, { veiculo, rfid }) {
      try {
        if (rfid.public_id && veiculo.id) {
          const del = await PatioApi.deleteRFID(veiculo.id, rfid.public_id)
          return Promise.resolve(del)
        }
      } catch (err) {
        return Promise.reject(err)
      }
    },

    // terminal
    async getTerminal({ commit }, params) {
      let itens = await PatioApi.getTerminal(params)
      commit('setTerminal', itens)
    },
    async addTerminal({ dispatch }, terminal) {
      if (!(terminal.terminal && terminal.tenant)) return
      await PatioApi.addTerminal(terminal.terminal)
      dispatch('getTerminal', { tenant: terminal.tenant })
    },
    async editTerminal({ dispatch }, terminal) {
      if (!(terminal.terminal && terminal.tenant)) return
      await PatioApi.editTerminal(terminal.terminal)
      dispatch('getTerminal', { tenant: terminal.tenant })
    },
    async deleteTerminal({ dispatch }, terminal) {
      if (!(terminal.id && terminal.tenant)) return
      const isDeleted = await PatioApi.deleteTerminal(terminal.id)
      dispatch('getTerminal', { tenant: terminal.tenant })
      return isDeleted
    },

    // tarifa
    async getTarifa({ commit }) {
      const itens = await PatioApi.getTarifa()
      commit('setTarifa', itens)
    },
    async addTarifa({ dispatch }, novo) {
      await PatioApi.addTarifa(novo)
      dispatch('getTarifa')
    },
    async editTarifa({ dispatch }, novo) {
      await PatioApi.editTarifa(novo)
      dispatch('getTarifa')
    },

    // tambor
    async getTambor({ commit }) {
      const itens = await PatioApi.getTambor()
      commit('setTambor', itens)
    },
    async addTambor({ dispatch }, novo) {
      await PatioApi.addTambores(novo)
      dispatch('getTambor')
    },
    async editTambor({ dispatch }, novo) {
      await PatioApi.editTambores(novo)
      dispatch('getTambores')
    },

    // status caminhao
    async getStatusCaminhao({ commit }) {
      const itens = await PatioApi.getStatusCaminhao()
      commit('setStatusCaminhao', itens)
    },
    async addStatusCaminhao({ dispatch }, novo) {
      await PatioApi.addStatusCaminhao(novo)
      dispatch('getStatusCaminhao')
    },
    async editStatusCaminhao({ dispatch }, novo) {
      await PatioApi.editStatusCaminhao(novo)
      dispatch('getStatusCaminhao')
    },

    // produto

    // embalagens
    async getEmbalagensByFornecedor({ commit }, fornecedor_id) {
      const embalagens = await PatioApi.getEmbalagensByFornecedor(fornecedor_id)
      commit('setEmbalagens', embalagens)
    },
    async getEmbalagens({ commit }, params) {
      const embalagens = await PatioApi.getEmbalagens(params)
      commit('setEmbalagens', embalagens)
    },
    async addEmbalagem({ dispatch }, novaEmbalagem) {
      await PatioApi.addEmbalagem(novaEmbalagem)
      dispatch('getEmbalagens', { proprietario: novaEmbalagem.proprietario })
    },
    async editEmbalagem({ dispatch }, embalagem) {
      await PatioApi.editEmbalagem(embalagem)
      dispatch('getEmbalagens', { proprietario: embalagem.proprietario })
    },
    async deleteEmbalagem({ dispatch }, embalagem) {
      await PatioApi.deleteEmbalagem(embalagem.embalagem_id)
      dispatch('getEmbalagens', { proprietario: embalagem.proprietario })
    },

    // formaspagamento
    async getFormasPagamento({ commit }) {
      const formaspagamento = await PatioApi.getFormasPagamento()
      commit('setFormaPagamento', formaspagamento)
    },
    async addFormaPagamento({ commit }, novaFormaPagamento) {
      const FormaPagamentoAdicionada = await PatioApi.addFormaPagamento(
        novaFormaPagamento
      )
      commit('addFormaPagamento', FormaPagamentoAdicionada)
    },
    async editFormaPagamento({ commit }, novoValor) {
      const FormaPagamentoAlterada = await PatioApi.editFormaPagamento(
        novoValor
      )
      commit('editFormaPagamento', FormaPagamentoAlterada)
    },

    // servicos
    async getServicos({ commit }, params) {
      try {
        const servicos = await PatioApi.getServicos(params)
        commit('setServicos', servicos)
        return Promise.resolve(servicos)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getServicosAtivos({ commit }, params) {
      try {
        const servicos = await PatioApi.getServicos(params)
        commit('setServicos', servicos)
        return Promise.resolve(servicos)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async getServicosNaoAtivos({ commit }, params) {
      try {
        const servicos = await PatioApi.getServicos(params)
        commit('SET_SERVICOS_NAO_ATIVOS', servicos)
        return Promise.resolve(servicos)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async addServico({ commit }, novoServico) {
      const servicoAdicionado = await PatioApi.addServico(novoServico)
      commit('addServico', servicoAdicionado)
    },
    async editServico({ commit }, novoValor) {
      const servicoAlterado = await PatioApi.editServico(novoValor)
      commit('editServico', servicoAlterado)
    },
    async deleteServico({ commit }, id) {
      await PatioApi.deleteServico(id)
      commit('deleteServico', id)
    },
    async desativarServico({ commit }, servico) {
      try {
        await PatioApi.desativarServico(servico)
        commit('REMOVE_SERVICO', servico)
        commit('ADD_SERVICO_NAO_ATIVO', servico)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async ativarServico({ commit }, servico) {
      try {
        await PatioApi.ativarServico(servico)
        commit('REMOVE_SERVICO_NAO_ATIVO', servico)
        commit('ADD_SERVICO', servico)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async enviaClassificacao(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.enviaClassificacaoLogOne(
          tenant,
          agendamento
        )
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async enviaPortoNet(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.enviaPortoNet(tenant, agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async cadastraMotoristaRonda(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.cadastraMotoristaRonda(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async confirmaChegadaIport(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.confirmaChegadaIport(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async enviarComandoCancela(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.enviarComandoCancela(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async resetarClassificacao(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.resetarClassificacao(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async finalizaClassificacao(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.finalizaClassificacaoLogOne(
          tenant,
          agendamento
        )
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async confirmaSaidaIport(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.confirmaSaidaIport(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async estornarStatusInicial({ commit }, { agendamento }) {
      try {
        const { data } = await PatioApi.estornarStatusInicial(agendamento)
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async estornarCancelamento({ commit }, { agendamento }) {
      try {
        const { data } = await PatioApi.estornarCancelamento(agendamento)
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async autorizarAgendamento({ commit }, { agendamento }) {
      try {
        const { data } = await PatioApi.autorizarAgendamento(agendamento)
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async recusarAgendamento({ commit }, { agendamento }) {
      try {
        const { data } = await PatioApi.recusarAgendamento(agendamento)
        commit('DELETE_AGENDAMENTO', agendamento)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async entradaBuffer({ commit }, { agendamento }) {
      try {
        const data = await PatioApi.entradaBuffer(agendamento)
        commit('EDIT_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async saidaBuffer({ commit }, { agendamento }) {
      try {
        const data = await PatioApi.saidaBuffer(agendamento)
        commit('EDIT_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async updateFast({ commit }, { agendamento }) {
      try {
        const data = await PatioApi.updateFast(agendamento)
        commit('EDIT_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async cobranca_adicional(
      { rootState },
      { agendamento, cobranca },
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.cobranca_adicional(
          tenant,
          agendamento,
          cobranca
        )
        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    // etapas
    async nextStep(
      { rootState },
      agendamento,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.nextStep(tenant, agendamento)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async previousStep(
      { rootState },
      agendamento,
      data,
      tenant = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.previousStep(tenant, agendamento, data)

        return Promise.resolve(data)
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getEtapas({ commit }, id_servico) {
      try {
        const etapas = await PatioApi.getEtapas(id_servico)
        commit('setEtapas', { id_servico, etapas })
        return Promise.resolve({ id_servico, etapas })
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async getEtapasParams({ commit }, params) {
      try {
        let id_servico = params.id
        const etapas = await PatioApi.getEtapasParams(params)
        commit('setEtapas', { id_servico, etapas })
        return Promise.resolve({ id_servico, etapas })
      } catch (err) {
        return Promise.reject(err)
      }
    },

    async addEtapa({ commit }, novoEtapa) {
      const id_servico = novoEtapa.id_servico
      const etapaAdicionado = await PatioApi.addEtapa(
        id_servico,
        novoEtapa.etapas
      )
      commit('addEtapa', { etapas: etapaAdicionado, id_servico })
      return etapaAdicionado
    },
    async editEtapa({ commit }, novoValor) {
      const id_servico = novoValor.id_servico
      const etapaAlterado = await PatioApi.editEtapa(
        id_servico,
        novoValor.etapas.public_id,
        novoValor.etapas
      )
      commit('editEtapa', { etapas: etapaAlterado, id_servico })
    },
    async deleteEtapa({ commit }, etapa) {
      await PatioApi.deleteEtapa(etapa.id_servico, etapa.id)
      commit('deleteEtapa', etapa)
    },

    async getServicoTarifa({ commit }, params) {
      const { tipoTarifa, proprietario, servico } = params
      const tarifas = await PatioApi.getServicoTarifa(tipoTarifa, {
        proprietario,
        servico,
      })

      commit('setTarifas', tarifas)
    },

    async addServicoTarifa({ dispatch }, tarifa) {
      if (!tarifa.tipoTarifa) return

      await PatioApi.addServicoTarifa(tarifa)

      dispatch('getServicoTarifa', tarifa)
    },

    async editServicoTarifa({ dispatch }, tarifa) {
      if (!tarifa.tipoTarifa) return

      await PatioApi.editServicoTarifa(tarifa)

      dispatch('getServicoTarifa', tarifa)
    },

    async deleteServicoTarifa({ commit }, tarifa) {
      await PatioApi.deleteServicoTarifa({
        id: tarifa.id,
        tipoTarifa: tarifa.tipoTarifa,
      })
      commit('deleteTarifa', tarifa.id)
    },

    async getServicoAutorizadoTarifa({ commit }, params) {
      const { tipoTarifa, proprietario, servico_autorizado } = params
      const tarifas = await PatioApi.getServicoAutorizadoTarifa(tipoTarifa, {
        proprietario,
        servico_autorizado,
      })

      commit('setTarifas', tarifas)
    },

    async addServicoAutorizadoTarifa({ dispatch }, tarifa) {
      if (!tarifa.tipoTarifa) return

      await PatioApi.addServicoAutorizadoTarifa(tarifa)

      dispatch('getServicoAutorizadoTarifa', tarifa)
    },

    async editServicoAutorizadoTarifa({ dispatch }, tarifa) {
      if (!tarifa.tipoTarifa) return

      await PatioApi.editServicoAutorizadoTarifa(tarifa)

      dispatch('getServicoAutorizadoTarifa', tarifa)
    },

    async deleteServicoAutorizadoTarifa({ commit }, tarifa) {
      await PatioApi.deleteServicoAutorizadoTarifa({
        id: tarifa.id,
        tipoTarifa: tarifa.tipoTarifa,
      })
      commit('deleteTarifa', tarifa.id)
    },

    // pedidos
    async getPedido({ commit }, public_id) {
      try {
        const pedido = await PatioApi.getPedido(public_id)
        commit('SET_PEDIDO', pedido)
        return Promise.resolve(pedido)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPedidos({ commit }, params) {
      try {
        const pedidos = await PatioApi.getPedidos(params)
        commit('SET_PEDIDOS', pedidos)
        return Promise.resolve(pedidos)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getPedidosRelatorio({ commit }, params) {
      try {
        const pedidos = await PatioApi.getPedidosRelatorio(params)
        commit('SET_PEDIDOS', pedidos)
        return Promise.resolve(pedidos)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async addPedido({ commit }, novosPedidos) {
      try {
        const pedidos = await PatioApi.addPedido(novosPedidos)
        commit('ADD_PEDIDO', pedidos)
        return Promise.resolve(pedidos)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async editPedido({ commit }, pedidoAtualizado) {
      try {
        const pedido = await PatioApi.editPedido(pedidoAtualizado)
        commit('EDIT_PEDIDO', pedido)
        return Promise.resolve(pedido)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async deletePedido({ commit }, pedido) {
      try {
        await PatioApi.deletePedido(pedido.public_id)
        commit('DELETE_PEDIDO', pedido)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // servicos Caminhao
    async getServicosCaminhao({ commit }) {
      const servicos = await PatioApi.getServicosCaminhao()
      commit('setServicosCaminhao', servicos)
    },
    async addServicoCaminhao({ commit }, novoServico) {
      const servicoCaminhaoAdicionado = await PatioApi.addServicoCaminhao(
        novoServico
      )
      commit('addServico', servicoCaminhaoAdicionado)
    },
    async editServicoCaminhao({ commit }, novoValor) {
      const servicoCaminhaoAlterado = await PatioApi.editServicoCaminhao(
        novoValor
      )
      commit('editServicoCaminhao', servicoCaminhaoAlterado)
    },

    // navios
    async getNavios({ commit }, params) {
      try {
        const navios = await PatioApi.getNavios(params)
        commit('setNavios', navios)
        return Promise.resolve(navios)
      } catch (err) {
        return Promise.reject(err)
      }
    },
    async addNavio({ commit }, novo) {
      const navioAdicionado = await PatioApi.addNavio(novo)
      commit('addNavio', navioAdicionado)
    },
    async editNavio({ commit }, novo) {
      const navioAlterado = await PatioApi.editServico(novo)
      commit('editNavio', navioAlterado)
    },

    // cancelas
    async getCancelas({ commit }) {
      const cancelas = await PatioApi.getCancelas()
      commit('setCancelas', cancelas)
    },
    async addCancela({ commit }, novoCancela) {
      const cancelaAdicionado = await PatioApi.addCancela(novoCancela)
      commit('addCancela', cancelaAdicionado)
    },
    async editCancela({ commit }, novoValor) {
      const cancelaAlterado = await PatioApi.editCancela(novoValor)
      commit('editCancela', cancelaAlterado)
    },

    // senhasAtendimento
    async getSenhasAtendimento({ commit }, params) {
      try {
        const data = await PatioApi.getSequenciaAtendimentoParams(params)
        const { results, count } = data
        commit('setSenhasAtendimento', results)
        commit('setSenhasAtendimentoCount', count)
        return Promise.resolve(data)
      } catch (error) {
        commit('setSenhasAtendimento', {})
        return Promise.reject(error)
      }
    },
    async getGerarSenhasAtendimento({ commit }, params) {
      try {
        const senha = await PatioApi.getGerarSenhasAtendimento(params)
        return Promise.resolve(senha)
      } catch (error) {
        commit('setSenhasAtendimento', {})
        return Promise.reject(error)
      }
    },

    async getGerarSenhasAtendimentoType({ commit }, params) {
      try {
        const senha = await PatioApi.getGerarSenhasAtendimentoType(params)
        return Promise.resolve(senha)
      } catch (error) {
        commit('setSenhasAtendimentoType', {})
        return Promise.reject(error)
      }
    },

    async addSenhasAtendimento({ commit }, novoSenhasAtendimento) {
      const SenhasAtendimentoAdicionado = await PatioApi.addSenhasAtendimento(
        novoSenhasAtendimento
      )
      commit('addSenhasAtendimento', SenhasAtendimentoAdicionado)
    },
    async editSenhasAtendimento({ commit }, novoValor) {
      const SenhasAtendimentoAlterado = await PatioApi.editSenhasAtendimento(
        novoValor
      )
      commit('SenhasAtendimento', SenhasAtendimentoAlterado)
    },
    async deleteSenhasAtendimento(senhasAtendimento) {
      await PatioApi.deleteSenhasAtendimento(senhasAtendimento)
    },
    // motivoscancelamento
    async getMotivosCancelamento({ commit }, params) {
      try {
        const motivosCancelamento = await PatioApi.getMotivosCancelamentoParams(
          params
        )
        commit('setMotivosCancelamento', motivosCancelamento)
        return Promise.resolve(motivosCancelamento)
      } catch (error) {
        commit('setMotivosCancelamento', [])
        return Promise.reject(error)
      }
    },
    async addMotivoCancelamento({ commit }, novoMotivoCancelamento) {
      const MotivoCancelamentoAdicionado = await PatioApi.addMotivoCancelamento(
        novoMotivoCancelamento
      )
      commit('addMotivoCancelamento', MotivoCancelamentoAdicionado)
    },
    async editMotivoCancelamento({ commit }, novoValor) {
      const MotivoCancelamentoAlterado = await PatioApi.editMotivoCancelamento(
        novoValor
      )
      commit('editMotivoCancelamento', MotivoCancelamentoAlterado)
    },
    async desativarMotivoCancelamento(motivosCancelamento) {
      await PatioApi.desativarMotivoCancelamento(motivosCancelamento)
    },
    async ativarMotivoCancelamento(ativarMotivosCancelamento) {
      await PatioApi.ativarMotivoCancelamento(ativarMotivosCancelamento)
    },
    async getMotivosInativos({ commit }, params) {
      try {
        const motivosInativos = await PatioApi.getMotivosInativos(params)
        commit('setMotivosCancelamentoInativos', motivosInativos)
        return Promise.resolve(motivosInativos)
      } catch (error) {
        commit('setMotivosCancelamentoInativos', [])
        return Promise.reject(error)
      }
    },

    // blacklists
    async getBlacklists({ commit }, params) {
      const blacklists = await PatioApi.getBlacklistParams(params)
      commit('setBlacklists', blacklists)
    },
    async addBlacklist({ commit }, novoBlacklist) {
      const BlacklistAdicionado = await PatioApi.addBlacklist(novoBlacklist)
      commit('addBlacklist', BlacklistAdicionado)
    },
    async editBlacklist({ commit }, novoValor) {
      const BlacklistAlterado = await PatioApi.editBlacklist(novoValor)
      commit('editBlacklist', BlacklistAlterado)
    },
    async deleteBlacklist(blacklist) {
      await PatioApi.deleteBlacklist(blacklist)
    },

    // itensLaudoCheckList
    async getItensLaudoCheckListParams({ commit }, params) {
      try {
        const itensLaudoCheckList = await PatioApi.getItensLaudoCheckListParams(
          params
        )
        commit('setItensLaudoCheckList', itensLaudoCheckList)
        return Promise.resolve(itensLaudoCheckList)
      } catch (error) {
        commit('setItensLaudoCheckList', [])
        return Promise.reject(error)
      }
    },
    async addItemLaudoCheckList({ commit }, novoItemLaudoCheckList) {
      const ItemLaudoCheckListAdicionado = await PatioApi.addItemLaudoCheckList(
        novoItemLaudoCheckList
      )
      commit('addItemLaudoCheckList', ItemLaudoCheckListAdicionado)
    },
    async desativarItemLaudoCheckList(itensCancelamento) {
      await PatioApi.desativarItemLaudoCheckList(itensCancelamento)
    },
    async ativarItemLaudoCheckList(ativarItensLaudoCheckList) {
      await PatioApi.ativarItemLaudoCheckList(ativarItensLaudoCheckList)
    },
    async getItensLaudoCheckListInativos({ commit }, params) {
      try {
        const itensLaudoCheckListInativos = await PatioApi.getItensLaudoCheckListInativos(
          params
        )
        commit('setItensLaudoCheckListInativos', itensLaudoCheckListInativos)
        return Promise.resolve(itensLaudoCheckListInativos)
      } catch (error) {
        commit('setItensLaudoCheckListInativos', [])
        return Promise.reject(error)
      }
    },

    // tefs
    async getTefs({ commit }, params) {
      const tefs = await PatioApi.getTefsParams(params)
      commit('setTefs', tefs)
    },
    async addTefs({ commit }, novoTefs) {
      const tefsAdicionado = await PatioApi.addTefs(novoTefs)
      commit('addTefs', tefsAdicionado)
    },
    async editTefs({ commit }, novoValor) {
      const tefsAlterado = await PatioApi.editTefs(novoValor)
      commit('editTefs', tefsAlterado)
    },

    // setores
    async getSetor(
      { commit, rootState },
      { setor_public_id, estacionamento_public_id }
    ) {
      const empresa = rootState.auth.empresaAtual.public_id
      try {
        const setor = await PatioApi.getSetor(
          setor_public_id,
          estacionamento_public_id,
          empresa
        )
        commit('setSetor', setor)
        return Promise.resolve(setor)
      } catch (error) {
        commit('setSetor', {})
        return Promise.reject(error)
      }
    },
    async getSetores({ commit, rootState }, estacionamento) {
      const empresa = rootState.auth.empresaAtual.public_id
      try {
        const setores = await PatioApi.getSetores(estacionamento, empresa)
        commit('setSetores', setores)
        return Promise.resolve(setores)
      } catch (error) {
        commit('setSetores', [])
        return Promise.reject(error)
      }
    },
    async addSetor({ commit, rootState }, novoSetor) {
      const empresa = rootState.auth.empresaAtual.public_id
      const estacionamento = novoSetor.estacionamento.public_id
      const setorAdicionado = await PatioApi.addSetor(
        novoSetor,
        estacionamento,
        empresa
      )
      commit('addSetor', setorAdicionado)
    },
    async editSetor({ commit, rootState }, novoValor) {
      const empresa = rootState.auth.empresaAtual.public_id
      const estacionamento = novoValor.estacionamento.public_id
      const setorAlterado = await PatioApi.editSetor(
        novoValor,
        estacionamento,
        empresa
      )
      commit('editSetor', setorAlterado)
    },
    async deleteSetor({ commit, rootState }, id, estacionamento) {
      const empresa = rootState.auth.empresaAtual.public_id
      await PatioApi.deleteSetor(id, estacionamento, empresa)
      commit('deleteSetor', id)
    },
    // convenios / convênios
    async addConvenio(
      { commit, rootState },
      convenio,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addConvenio(empresa, convenio)
        commit('ADD_CONVENIO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async editConvenio(
      { commit, rootState },
      convenio,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editConvenio(empresa, convenio)
        commit('EDIT_CONVENIO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getConvenios(
      { commit, rootState },
      params,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getConvenios(empresa, params)
        commit('SET_CONVENIOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getConveniosByParams({ commit, rootState }, payload) {
      let { empresa, ...params } = payload
      if (!empresa) empresa = rootState.auth.empresaAtual
      try {
        const data = await PatioApi.getConveniosByParams(empresa, params)
        commit('SET_CONVENIOS_NAO_ATIVOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async desativarConvenio(
      { commit, rootState },
      convenio,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.desativarConvenio(empresa, convenio)
        commit('REMOVE_CONVENIO', convenio)
        commit('ADD_CONVENIO_NAO_ATIVO', convenio)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async ativarConvenio(
      { commit, rootState },
      convenio,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.ativarConvenio(empresa, convenio)
        commit('REMOVE_CONVENIO_NAO_ATIVO', convenio)
        commit('ADD_CONVENIO', convenio)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async removerConvenio(
      { commit, rootState },
      convenio,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerConvenio(empresa, convenio)
        if (convenio.ativo) {
          commit('REMOVE_CONVENIO', convenio)
        } else {
          commit('REMOVE_CONVENIO_NAO_ATIVO', convenio)
        }
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e.response.data)
      }
    },

    // Agendamento / Agendamentos
    async addAgendamento(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addAgendamento(empresa, agendamento)
        commit('ADD_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async editAgendamento(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editAgendamento(empresa, agendamento)
        commit('EDIT_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamento(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendamento(empresa)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoByParams(
      { commit, rootState },
      { params, empresa = rootState.auth.empresaAtual }
    ) {
      try {
        const data = await PatioApi.getAgendamento(empresa, params)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAllAgendamentosByParams({ commit }, params) {
      try {
        const data = await PatioApi.getAllAgendamento(params)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getConsultaGerenciamentoServico({ commit }, agendamento) {
      try {
        const data = await PatioApi.getConsultaGerenciamentoServico(agendamento)
        commit('SET_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoGerenciamentoServico({ commit }, agendamento) {
      try {
        const data = await PatioApi.getAgendamentoGerenciamentoServico(
          agendamento
        )
        commit('SET_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoRelatorio({ commit }, params) {
      try {
        const data = await PatioApi.getAgendamentoRelatorio(params)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoRelatorioOrigem({ commit }, params) {
      try {
        const data = await PatioApi.getAgendamentoRelatorioOrigem(params)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoRelatorioCancelados({ commit }, params) {
      try {
        const data = await PatioApi.getAgendamentoRelatorioCancelados(params)
        commit('SET_AGENDAMENTOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoListagem({ commit, rootState }, params) {
      try {
        if (rootState.auth.empresaAtual.gate && params.proprietario) {
          delete params.gate
        }
        const data = await PatioApi.getAgendamentoListagem(params)
        commit('SET_AGENDAMENTOS', data)
        commit('SET_AGENDAMENTOS_RESUMO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getRelatorioServico(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRelatorioServico(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getRelatorioServicoEixo(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRelatorioServicoEixo(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getProgramacaoAtendidos(
      { rootState },
      params,
      fornecedor = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getProgramacaoAtendidos(fornecedor, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getRelatorioServicoDetalhado(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRelatorioDetalhado(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getRelatorioProdutoEixo(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRelatorioProdutoEixo(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getResumoPatio(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getResumoPatio(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getResumoSubProduto(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getResumoSubProduto(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getResumoTerminal(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getResumoTerminal(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getRelatorioServicoTotal(
      { rootState },
      params,
      gate = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getRelatorioTotal(gate, params)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async removerAgendamento(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.deleteAgendamento(empresa, agendamento)
        commit('REMOVE_AGENDAMENTO', agendamento)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Agendamento Etapa / Agendamentos Etapa
    async addAgendamentoEtapa({ commit }, agendamentoEtapa) {
      try {
        const data = await PatioApi.addAgendamentoEtapa(agendamentoEtapa)
        commit('ADD_AGENDAMENTO_ETAPA', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async editAgendamentoEtapa(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editAgendamento(empresa, agendamento)
        commit('EDIT_AGENDAMENTO_ETAPA', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoEtapa(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendamentos(empresa)
        commit('SET_AGENDAMENTOS_ETAPA', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async removerAgendamentoEtapa(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerConvenio(empresa, agendamento)
        commit('REMOVE_AGENDAMENTO', agendamento)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e.response.data)
      }
    },

    // Agendamento Servico / Agendamentos Servico
    async addAgendamentoServico(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addAgendamento(empresa, agendamento)
        commit('ADD_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async editAgendamentoServico(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editAgendamento(empresa, agendamento)
        commit('EDIT_AGENDAMENTO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getAgendamentoServico(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getAgendamentos(empresa)
        commit('SET_CONVENIOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async removerAgendamentoServico(
      { commit, rootState },
      agendamento,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerConvenio(empresa, agendamento)
        commit('REMOVE_AGENDAMENTO', agendamento)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e.response.data)
      }
    },

    // Triagem

    async getTriagem(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getTriagem(empresa)
        commit('SET_TRIANGENS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // Suspensao
    async getSuspensoes(
      { commit, rootState },
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getSuspensoes(empresa)
        commit('SET_SUSPENSOES', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async addSuspensao(
      { commit, rootState },
      suspensao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.addSuspensao(empresa, suspensao)
        commit('ADD_SUSPENSAO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async editSuspensao(
      { commit, rootState },
      suspensao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.editSuspensao(empresa, suspensao)
        commit('EDIT_SUSPENSAO', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async removerSuspensao(
      { commit, rootState },
      suspensao,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        await PatioApi.removerSuspensao(empresa, suspensao)
        commit('DELETE_SUSPENSAO', suspensao)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getServicosAutorizadosFornecedor(
      { commit, rootState },
      fornecedor,
      empresa = rootState.auth.empresaAtual
    ) {
      try {
        const data = await PatioApi.getServicosAutorizadosFornecedor(
          fornecedor,
          empresa
        )
        commit('SET_SERVICOS_AUTORIZADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getServicosAutorizadosFornecedorParams({ commit }, params) {
      try {
        const data = await PatioApi.getServicosAutorizadosFornecedorParams(
          params
        )
        commit('SET_SERVICOS_AUTORIZADOS', data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getItensLaudoCheckList({ rootState }, agendamento) {
      try {
        const empresaAtual = rootState.auth.empresaAtual
        const data = await PatioApi.getItensLaudoCheckList(
          empresaAtual,
          agendamento
        )
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async addLaudoItemCheckList({ commit }, novoLaudoItemCheckList) {
      const LaudoItemCheckListAdicionado = await PatioApi.addLaudoItemCheckList(
        novoLaudoItemCheckList
      )
      commit('addLaudoItemCheckList', LaudoItemCheckListAdicionado)
    },
    async editLaudoItemCheckList({ commit }, novoValor) {
      const laudoItemCheckListAdicionado = await PatioApi.editLaudoItemCheckList(
        novoValor
      )
      commit('editLaudoItemCheckList', laudoItemCheckListAdicionado)
    },

    async getLaudoLimpezaAgendamento({ rootState }, agendamento) {
      try {
        const empresaAtual = rootState.auth.empresaAtual
        const data = await PatioApi.getLaudoLimpezaAgendamento(
          empresaAtual,
          agendamento
        )
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
