import axios from 'axios'
import moment from 'moment'
class PatioApi {
  // dashboard
  async getDashboardCountByParams(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/count/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEstacionamentos(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/estacionamentos/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async addEstacionamento(novoEstacionamento, empresa) {
    const { data } = await axios.post(
      `api/v1/tenants/tenants/${empresa}/estacionamentos/`,
      novoEstacionamento
    )
    return data
  }
  async editEstacionamento(novoValor, empresa) {
    const public_id = novoValor.public_id
    const { data } = await axios.put(
      `api/v1/tenants/tenants/${empresa}/estacionamentos/${public_id}/`,
      {
        descricao: novoValor.descricao,
      }
    )
    return data
  }

  // regiao
  async getRegioes(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/regioes/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getRegioesColaborator(empresa, user) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/colaboradores/${user}/regioes`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async cadastrarRegiao(empresa, regiao) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa.public_id}/regioes/`,
        regiao
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async updateRegiao(empresa, regiao) {
    try {
      const { data } = await axios.put(
        `api/v1/tenants/tenants/${empresa.public_id}/regioes/${regiao.public_id}/`,
        regiao
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async desativarRegiao(empresa, regiao) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/regioes/${regiao.public_id}/desativar/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getGerarSenhasAtendimento(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/sequenciaatendimento/gerar_sequencia/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getGerarSenhasAtendimentoType(params) {
    try {
      const {
        data,
      } = await axios.get(`api/v1/patio/sequenciaatendimento_type/`, { params })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // representante
  async getRepresentantes(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getRepresentante(publicID, empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes/${publicID}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getRepresentanteByCPF(empresa, cpf) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes/`,
        { params: { cpf } }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async cadastrarRepresentante(empresa, representante) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes/`,
        representante
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async removerRepresentante(empresa, representante) {
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes/${representante.public_id}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // representante territorial
  async getRepresentantesTerritoriais(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes-territoriais/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getRepresentantesTerritoriaisByRepresentantePublicID(
    representantePublicID,
    empresa
  ) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes-territoriais/`,
        { params: { representante: representantePublicID } }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async cadastrarRepresentanteTerritorial(empresa, representante) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes-territoriais/`,
        representante
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async atualizarRepresentanteTerritorial(empresa, representante) {
    try {
      const { data } = await axios.put(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes-territoriais/${representante.public_id}/`,
        representante
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async removerRepresentanteTerritorial(empresa, representante) {
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/tenants/${empresa.public_id}/representantes-territoriais/${representante.public_id}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // unidade
  async getUnidade() {
    const { data } = await axios.get('api/v1/patio/unidade/')
    return data
  }

  async addUnidade(novo) {
    const { data } = await axios.post('api/v1/patio/unidade/', novo)
    return data
  }

  async editUnidade(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/unidade/${id}/`, novo)
    return data
  }

  async getProgramacaoUnidade(programacaoUnidade) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesUnidade/${programacaoUnidade}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getProgramacoesUnidade(empresa, params = {}) {
    try {
      params['proprietario'] = empresa.public_id
      const { data } = await axios.get(`api/v1/patio/programacoesUnidade/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getProgramacoesUnidadeResumo(empresa, params = {}) {
    try {
      params['proprietario'] = empresa.public_id
      const { data } = await axios.get(
        `api/v1/patio/programacoesUnidade/resumo/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacoesRegiaoResumo(empresa, params = {}) {
    try {
      // params['origem'] = empresa.public_id
      const { data } = await axios.get(
        `api/v1/patio/programacoesRegiao/resumo/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacaoDetalhe(type, public_id) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoes${type}/${public_id}/detalhes/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getProgramacaoAtendidos(empresa, params = {}) {
    try {
      params['proprietario'] = empresa.public_id
      const {
        data,
      } = await axios.get(
        `api/v1/patio/programacoesUnidade/relatorio_atendidos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addProgramacaoUnidade(novoProgramacaoUnidade) {
    try {
      const { data } = await axios.post(`api/v1/patio/programacoesUnidade/`, {
        proprietario: novoProgramacaoUnidade.proprietario
          ? novoProgramacaoUnidade.proprietario.public_id
          : null,
        fornecedor: novoProgramacaoUnidade.fornecedor
          ? novoProgramacaoUnidade.fornecedor.public_id
          : null,
        produto: novoProgramacaoUnidade.produto
          ? novoProgramacaoUnidade.produto.id
          : null,
        sub_produto: novoProgramacaoUnidade.sub_produto
          ? novoProgramacaoUnidade.sub_produto.id
          : null,
        terminal: novoProgramacaoUnidade.terminal
          ? novoProgramacaoUnidade.terminal.public_id
          : null,
        tipo_operacao: novoProgramacaoUnidade.tipo_operacao
          ? novoProgramacaoUnidade.tipo_operacao.id
          : null,
        tipo_controle: novoProgramacaoUnidade.tipo_controle
          ? novoProgramacaoUnidade.tipo_controle.id
          : null,
        modalidades: novoProgramacaoUnidade.modalidades
          ? novoProgramacaoUnidade.modalidades
              .filter((element) => element.id != undefined)
              .map((element) => element.id)
          : [],
        carrocerias: novoProgramacaoUnidade.carrocerias
          ? novoProgramacaoUnidade.carrocerias
              .filter((element) => element.id != undefined)
              .map((element) => element.id)
          : [],
        observacao: novoProgramacaoUnidade.observacao
          ? novoProgramacaoUnidade.observacao
          : null,
        data_inicio: new Date(novoProgramacaoUnidade.data_inicio).toISOString(),
        data_fim: new Date(novoProgramacaoUnidade.data_fim).toISOString(),
        qt_peso: novoProgramacaoUnidade.qt_peso
          ? novoProgramacaoUnidade.qt_peso
          : 0,
        qt_caminhoes: novoProgramacaoUnidade.qt_caminhoes
          ? novoProgramacaoUnidade.qt_caminhoes
          : 0,
        original: novoProgramacaoUnidade.original
          ? novoProgramacaoUnidade.original.public_id
            ? novoProgramacaoUnidade.original.public_id
            : null
          : null,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async editProgramacaoUnidade(novoValor) {
    const public_id = novoValor.public_id
    const { data } = await axios.put(
      `api/v1/patio/programacoesUnidade/${public_id}/`,
      {
        proprietario: novoValor.proprietario
          ? novoValor.proprietario.public_id
          : null,
        fornecedor: novoValor.fornecedor
          ? novoValor.fornecedor.public_id
          : null,
        produto: novoValor.produto ? novoValor.produto.id : null,
        sub_produto: novoValor.sub_produto ? novoValor.sub_produto.id : null,
        terminal: novoValor.terminal ? novoValor.terminal.public_id : null,
        tipo_operacao: novoValor.tipo_operacao
          ? novoValor.tipo_operacao.id
          : null,
        tipo_controle: novoValor.tipo_controle
          ? novoValor.tipo_controle.id
          : null,
        modalidades: novoValor.modalidades
          ? novoValor.modalidades
              .filter((element) => element.id != undefined)
              .map((element) => element.id)
          : [],
        carrocerias: novoValor.carrocerias
          ? novoValor.carrocerias
              .filter((element) => element.id != undefined)
              .map((element) => element.id)
          : [],
        observacao: novoValor.observacao ? novoValor.observacao : null,
        data_inicio: new Date(novoValor.data_inicio).toISOString(),
        data_fim: new Date(novoValor.data_fim).toISOString(),
        qt_peso: novoValor.qt_peso ? novoValor.qt_peso : 0,
        qt_caminhoes: novoValor.qt_caminhoes ? novoValor.qt_caminhoes : 0,
        original: novoValor.original
          ? novoValor.original.public_id
            ? novoValor.original.public_id
            : null
          : null,
      }
    )
    return data
  }
  async deleteProgramacaoUnidade(programacaoUnidade) {
    try {
      const { data } = await axios.delete(
        `api/v1/patio/programacoesUnidade/${programacaoUnidade.public_id}/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  // ProgramacaoFornecedor
  async getProgramacoesFornecedor(empresa, params) {
    try {
      params['origem'] = empresa.public_id
      const { data } = await axios.get(`api/v1/patio/programacoesFornecedor/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacoesFornecedorSimple(empresa, params) {
    try {
      params['origem'] = empresa.public_id
      const { data } = await axios.get(
        `api/v1/patio/programacoesFornecedor/simple/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacaoFornecedor(programacaoFornecedor) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesFornecedor/${programacaoFornecedor.public_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addProgramacaoFornecedor(programacaoFornecedor) {
    const { data } = await axios.post(`api/v1/patio/programacoesFornecedor/`, {
      programacao_unidade: programacaoFornecedor.programacao_unidade
        ? programacaoFornecedor.programacao_unidade.public_id
        : null,
      regiao: programacaoFornecedor.regiao
        ? programacaoFornecedor.regiao.public_id
        : null,
      tipos_frete: programacaoFornecedor.tipos_frete
        ? programacaoFornecedor.tipos_frete
            .filter((element) => element.id != undefined)
            .map((element) => element.id)
        : [],
      qt_peso: programacaoFornecedor.qt_peso
        ? programacaoFornecedor.qt_peso
        : 0,
      qt_caminhoes: programacaoFornecedor.qt_caminhoes
        ? programacaoFornecedor.qt_caminhoes
        : 0,
      original: programacaoFornecedor.original
        ? programacaoFornecedor.original.public_id
          ? programacaoFornecedor.original.public_id
          : null
        : null,
    })
    return data
  }

  async editProgramacaoFornecedor(programacaoFornecedor) {
    const { data } = await axios.put(
      `api/v1/patio/programacoesFornecedor/${programacaoFornecedor.public_id}/`,
      {
        programacao_unidade: programacaoFornecedor.programacao_unidade
          ? programacaoFornecedor.programacao_unidade.public_id
          : null,
        regiao: programacaoFornecedor.regiao
          ? programacaoFornecedor.regiao.public_id
          : null,
        tipos_frete: programacaoFornecedor.tipos_frete
          ? programacaoFornecedor.tipos_frete
              .filter((element) => element.id != undefined)
              .map((element) => element.id)
          : [],
        qt_peso: programacaoFornecedor.qt_peso
          ? programacaoFornecedor.qt_peso
          : 0,
        qt_caminhoes: programacaoFornecedor.qt_caminhoes
          ? programacaoFornecedor.qt_caminhoes
          : 0,
        original: programacaoFornecedor.original
          ? programacaoFornecedor.original.public_id
            ? programacaoFornecedor.original.public_id
            : null
          : null,
      }
    )
    return data
  }

  async deleteProgramacaoFornecedor(programacaoFornecedor) {
    try {
      const data = await axios.delete(
        `api/v1/patio/programacoesFornecedor/${programacaoFornecedor.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // ProgramacaoRegiao
  async getProgramacoesRegiao(empresa, params) {
    params['origem'] = empresa.public_id
    try {
      const { data } = await axios.get(`api/v1/patio/programacoesRegiao/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacoesRegiaoAgendavel(empresa, params) {
    params['origem'] = empresa.public_id
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesRegiao/agendavel/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacaoRegiao(programacaoRegiao) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesRegiao/${programacaoRegiao.public_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addProgramacaoRegiao(programacaoRegiao) {
    const { data } = await axios.post(`api/v1/patio/programacoesRegiao/`, {
      programacao_fornecedor: programacaoRegiao.programacao_fornecedor
        ? programacaoRegiao.programacao_fornecedor.public_id
        : null,
      cliente: programacaoRegiao.cliente
        ? programacaoRegiao.cliente.public_id
        : null,
      pedidos: programacaoRegiao.pedidos
        ? programacaoRegiao.pedidos
            .filter((element) => element.public_id != '')
            .map((element) => element.public_id)
        : [],
      qt_peso: programacaoRegiao.qt_peso ? programacaoRegiao.qt_peso : 0,
      qt_caminhoes: programacaoRegiao.qt_caminhoes
        ? programacaoRegiao.qt_caminhoes
        : 0,
      original: programacaoRegiao.original
        ? programacaoRegiao.original.public_id
          ? programacaoRegiao.original.public_id
          : null
        : null,
    })
    return data
  }

  async editProgramacaoRegiao(programacaoRegiao) {
    const { data } = await axios.put(
      `api/v1/patio/programacoesRegiao/${programacaoRegiao.public_id}/`,
      {
        programacao_fornecedor: programacaoRegiao.programacao_fornecedor
          ? programacaoRegiao.programacao_fornecedor.public_id
          : null,
        cliente: programacaoRegiao.cliente
          ? programacaoRegiao.cliente.public_id
          : null,
        pedidos: programacaoRegiao.pedidos
          ? programacaoRegiao.pedidos
              .filter((element) => element.public_id != '')
              .map((element) => element.public_id)
          : [],
        qt_peso: programacaoRegiao.qt_peso ? programacaoRegiao.qt_peso : 0,
        qt_caminhoes: programacaoRegiao.qt_caminhoes
          ? programacaoRegiao.qt_caminhoes
          : 0,
        original: programacaoRegiao.original
          ? programacaoRegiao.original.public_id
            ? programacaoRegiao.original.public_id
            : null
          : null,
      }
    )
    return data
  }

  async deleteProgramacaoRegiao(programacaoRegiao) {
    try {
      const data = await axios.delete(
        `api/v1/patio/programacoesRegiao/${programacaoRegiao.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // ProgramacaoCliente
  async getProgramacoesCliente(empresa, params) {
    params['origem'] = empresa.public_id
    try {
      const { data } = await axios.get(`api/v1/patio/programacoesCliente/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacoesClienteAgendavel(empresa, params) {
    params['origem'] = empresa.public_id
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesCliente/agendavel`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getProgramacaoCliente(programacaoCliente) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/programacoesCliente/${programacaoCliente.public_id}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addProgramacaoCliente(programacaoCliente) {
    const { data } = await axios.post(`api/v1/patio/programacoesCliente/`, {
      programacao_regiao: programacaoCliente.programacao_regiao
        ? programacaoCliente.programacao_regiao.public_id
        : null,
      transportadora: programacaoCliente.transportadora
        ? programacaoCliente.transportadora.public_id
        : null,
      qt_peso: programacaoCliente.qt_peso ? programacaoCliente.qt_peso : 0,
      qt_caminhoes: programacaoCliente.qt_caminhoes
        ? programacaoCliente.qt_caminhoes
        : 0,
      original: programacaoCliente.original
        ? programacaoCliente.original.public_id
          ? programacaoCliente.original.public_id
          : null
        : null,
    })
    return data
  }

  async getJanelasCargaDescarga(params) {
    try {
      const { data } = await axios.get(`api/v1/patio/janelaCargaDescarga/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async editProgramacaoCliente(programacaoCliente) {
    const { data } = await axios.put(
      `api/v1/patio/programacoesCliente/${programacaoCliente.public_id}/`,
      {
        programacao_regiao: programacaoCliente.programacao_regiao
          ? programacaoCliente.programacao_regiao.public_id
          : null,
        transportadora: programacaoCliente.transportadora
          ? programacaoCliente.transportadora.public_id
          : null,
        qt_peso: programacaoCliente.qt_peso ? programacaoCliente.qt_peso : 0,
        qt_caminhoes: programacaoCliente.qt_caminhoes
          ? programacaoCliente.qt_caminhoes
          : 0,
        original: programacaoCliente.original
          ? programacaoCliente.original.public_id
            ? programacaoCliente.original.public_id
            : null
          : null,
      }
    )
    return data
  }

  async deleteProgramacaoCliente(programacaoCliente) {
    try {
      const data = await axios.delete(
        `api/v1/patio/programacoesCliente/${programacaoCliente.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getProgramacaoGestorTerritorial() {
    const { data } = await axios.get(
      'api/v1/patio/programacaogestorterritorial/'
    )
    return data
  }

  async addProgramacaoGestorTerritorial(novo) {
    const { data } = await axios.post(
      'api/v1/patio/programacaogestorterritorial/',
      novo
    )
    return data
  }

  async editProgramacaoGestorTerritorial(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/patio/programacaogestorterritorial/${id}/`,
      novo
    )
    return data
  }

  // embalagens
  async getEmbalagens(params) {
    const { data } = await axios.get('api/v1/patio/embalagens/', {
      params,
    })
    return data
  }

  // /api/v1/tenants/fornecedores/{parent_lookup_fornecedor}/embalagens/

  async getEmbalagensByFornecedor(fornecedor_id) {
    const { data } = await axios.get(`/api/v1/tenants/fornecedores/${fornecedor_id}/embalagens/`)
    return data
  }

  async addEmbalagem(novaEmbalagem) {
    const { data } = await axios.post('api/v1/patio/embalagens/', novaEmbalagem)
    return data
  }

  async editEmbalagem(editEmbalagem) {
    const id = editEmbalagem.id
    const { data } = await axios.patch(
      `api/v1/patio/embalagens/${id}/`,
      editEmbalagem
    )
    return data
  }

  async deleteEmbalagem(embalagem_id) {
    try {
      await axios.delete(`api/v1/patio/embalagens/${embalagem_id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // pedido
  async getQuantidadeRestante(params) {
    try {
      const { data } = await axios.get(
        'api/v1/patio/pedidos/quantidade_restante',
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async inativarPedido(params) {
    try {
      const { data } = await axios.post(
        'api/v1/patio/pedidos/inativar/',
        params
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getPedido(public_id) {
    try {
      const { data } = await axios.get(`api/v1/patio/pedidos/${public_id}/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getPedidos(params) {
    try {
      const { data } = await axios.get('api/v1/patio/pedidos/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getPedidosRelatorio(params) {
    try {
      const { data } = await axios.get('api/v1/patio/pedidos/relatorio', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addPedido(novo) {
    try {
      const { data } = await axios.post('api/v1/patio/pedidos/', novo)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editPedido(pedido) {
    try {
      const { data } = await axios.patch(
        `api/v1/patio/pedidos/${pedido.public_id}/`,
        pedido
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async deletePedido(public_id) {
    try {
      await axios.delete(`api/v1/patio/pedidos/${public_id}`)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject()
    }
  }

  // pedidos Agendados

  async deletePedidosAgendados(pedidoAgendado) {
    try {
      const { data } = await axios.delete(
        `api/v1/patio/pedidosAgendados/${pedidoAgendado.public_id}`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editPedidosAgendados(item) {
    const public_id = item.public_id
    const { data } = await axios.patch(
      `api/v1/patio/pedidosAgendados/${public_id}/`,
      item
    )
    return data
  }

  async getPedidosAgendados(params) {
    try {
      const { data } = await axios.get('api/v1/patio/pedidosAgendados/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // servico
  async getServicos(params) {
    try {
      const { data } = await axios.get('api/v1/patio/servicos/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getServicoByID(id) {
    try {
      const { data } = await axios.get(`api/v1/patio/servicos/${id}`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addServico(novo) {
    const { data } = await axios.post('api/v1/patio/servicos/', novo)
    return data
  }

  async editServico(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/servicos/${id}/`, novo)
    return data
  }

  async deleteServico(id) {
    try {
      await axios.delete(`api/v1/patio/servicos/${id}/`)
      return false
    } catch (err) {
      return false
    }
  }

  async desativarServico(servico) {
    try {
      await axios.get(`/api/v1/patio/servicos/${servico.id}/desativar/`)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async ativarServico(servico) {
    try {
      await axios.get(`/api/v1/patio/servicos/${servico.id}/ativar/`)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // Serviços autorizados

  async getServicosAutorizados(gate, fornecedor, params) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/gates/${gate}/fornecedores/${fornecedor}/servico-autorizado/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addServicoAutorizado(gate, fornecedor, novo) {
    const { data } = await axios.post(
      `/api/v1/tenants/gates/${gate}/fornecedores/${fornecedor}/servico-autorizado/`,
      novo
    )
    return data
  }

  async editServicoAutorizado(gate, fornecedor, id, novo) {
    const { data } = await axios.patch(
      `/api/v1/tenants/gates/${gate}/fornecedores/${fornecedor}/servico-autorizado/${id}/`,
      novo
    )
    return data
  }

  async deleteServicoAutorizado(gate, fornecedor, id) {
    try {
      await axios.delete(
        `/api/v1/tenants/gates/${gate}/fornecedores/${fornecedor}/servico-autorizado/${id}/`
      )
      return false
    } catch (err) {
      return false
    }
  }

  async cancelarAgendamento(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/cancelar/`,
        {
          agendamento: agendamento,
          motivo_cancelamento: agendamento.motivo_cancelamento,
          motivo_cancelamento_public_id:
            agendamento.motivo_cancelamento_public_id,
          cancelamento_em_massa: Boolean(agendamento.cancelamento_em_massa),
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async startPauseAgendamento(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/start_process_pause/`,
        {
          agendamento: agendamento,
          observacao: agendamento.observacao,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async stopPauseAgendamento(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/stop_process_pause/`,
        {
          agendamento: agendamento,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async cancelarServicoTriado(tenant, user, servicoTriado) {
    try {
      const { data } = await axios.post(
        `/api/v1/patio/servicoTriado/${servicoTriado.public_id}/cancelar/`,
        {
          motivo_cancelamento: servicoTriado.motivo_cancelamento,
          usuario_public_id: user.public_id,
          tenant_public_id: tenant.public_id,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAgendados(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/agendados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAgendadosProdutos(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/agendados_produtos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAgendadosDestinos(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/agendados_destinos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getMapaMovimentacaoVeiculo(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/mapa_movimentacao_veiculo/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidos(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosProdutosHoje(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_produtos_hoje/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosDestinosHoje(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_destinos_hoje/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosProdutosOntem(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_produtos_ontem/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosDestinosOntem(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_destinos_ontem/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosProdutosNaSemana(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_produtos_na_semana/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAtendidosDestinosNaSemana(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/atendidos_destinos_na_semana/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEmTransito(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/em_transito/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getAprovados(empresa, params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/solicitacoes/aprovados/`,
        { proprietairo: empresa.public_id, params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getDetalhesAprovados(empresa, params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/solicitacoes/detalhes-aprovados/`,
        {
          proprietairo: empresa.public_id,
          params,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getServicosExecutados(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/servicos_executados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getServicosExecutadosProdutos(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/servicos_executados_produtos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getServicosExecutadosDestinos(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/servicos_executados_destinos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getServicosExecutadosEtapas(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/servicos_executados_etapas/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getTaxaChegadaSemanal(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/taxa_chegada_semanal/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getProgramadoAtendidoSemanal(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/relatorio_atendidos_terminal/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getTaxaChegadaHora(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/taxa_chegada_hora/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getCarregados(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/carregados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getCarregadosProdutosDiaAnterior(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/carregados_produtos_dia_anterior/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getCarregadosDestinosDiaAnterior(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/carregados_destinos_dia_anterior/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getCarregadosProdutosDiaAtual(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/carregados_produtos_dia_atual/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getCarregadosDestinosDiaAtual(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/carregados_destinos_dia_atual/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getDescarregados(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/descarregados/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getDescarregadosProdutosDiaAnterior(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/descarregados_produtos_dia_anterior/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getDescarregadosDestinosDiaAnterior(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/descarregados_destinos_dia_anterior/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getDescarregadosProdutosDiaAtual(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/descarregados_produtos_dia_atual/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getDescarregadosDestinosDiaAtual(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/descarregados_destinos_dia_atual/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async cobranca_adicional(tenant, agendamento, cobranca) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/cobranca_adicional/`,
        {
          data_entrada: cobranca._data_entrada,
          data_saida: cobranca._data_saida,
          forma_pagamento: cobranca.forma_pagamento
            ? cobranca.forma_pagamento.public_id
            : null,
          transportadora: cobranca.transportadora
            ? cobranca.transportadora.transportadora.transportadora_id
            : null,
          servico: cobranca.servico ? cobranca.servico.public_id : null,
          valor_adicional: cobranca._valor_adicional,
          total_horas: cobranca._total_horas,
          total_horas_adicionais: cobranca._total_horas_adicionais,
        }
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  // etapas
  async nextStep(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/avancar_etapa/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async previousStep(tenant, agendamento, obs) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/recuar_etapa/`,
        obs
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async enviaClassificacao(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/envia_classificacao/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async enviaClassificacaoLogOne(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/envia_classificacao_log_one/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async enviaPortoNet(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/envia_porto_net/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async cadastraMotoristaRonda(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/cadastra_motorista_ronda/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async confirmaChegadaIport(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/confirma_chegada_iport/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async enviarComandoCancela(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/envia_comando_cancela/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async resetarClassificacao(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/resetar_classificacao/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async finalizaClassificacao(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/finaliza_classificacao/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async finalizaClassificacaoLogOne(tenant, agendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/finaliza_classificacao_log_one/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async confirmaSaidaIport(tenant, agendamento) {
    try {
      const dataTesc = await this.confirmaSaidaPatioTesc(tenant, agendamento)
      console.log(dataTesc)

      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${tenant.public_id}/agendamentos/${agendamento.public_id}/confirma_saida_iport/`
      )

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async estornarCancelamento(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/estornar_cancelamento/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async autorizarAgendamento(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/autorizar_agendamento/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async recusarAgendamento(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/recusar_agendamento/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async estornarStatusInicial(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/estornar_status_inicial/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async saidaBuffer(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/saida_buffer/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async updateFast(agendamento) {
    try {
      const { data } = await axios.patch(
        `/api/v1/patio/agendamento/${agendamento.public_id}/update_fast/`,
        agendamento
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async entradaBuffer(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/agendamento/${agendamento.public_id}/entrada_buffer/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEtapas(id) {
    try {
      const { data } = await axios.get(`api/v1/patio/servicos/${id}/etapas/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEtapasParams(params) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/servicos/${params.id}/etapas/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getEtapa(id_servico, id_etapa) {
    try {
      const { data } = await axios.get(
        `api/v1/patio/servicos/${id_servico}/etapas/${id_etapa}`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addEtapa(id, novo) {
    const { data } = await axios.post(
      `api/v1/patio/servicos/${id}/etapas/`,
      novo
    )
    return data
  }

  async editEtapa(id_servico, id_etapa, novo) {
    const { data } = await axios.patch(
      `api/v1/patio/servicos/${id_servico}/etapas/${id_etapa}/`,
      novo
    )
    return data
  }

  async deleteEtapa(id_servico, id_etapa) {
    try {
      await axios.delete(
        `api/v1/patio/servicos/${id_servico}/etapas/${id_etapa}/`
      )
      return false
    } catch (err) {
      return false
    }
  }

  async getServicoTarifa(tipoTarifa, params) {
    const { data } = await axios.get(
      `api/v1/patio/servicos-tarifa-${tipoTarifa}/`,
      {
        params,
      }
    )

    return data
  }

  async addServicoTarifa(tarifa) {
    if (!tarifa.tipoTarifa) return

    const { data } = await axios.post(
      `api/v1/patio/servicos-tarifa-${tarifa.tipoTarifa}/`,
      tarifa
    )
    return data
  }

  async editServicoTarifa(tarifa) {
    if (!tarifa.tipoTarifa) return

    const { data } = await axios.patch(
      `api/v1/patio/servicos-tarifa-${tarifa.tipoTarifa}/${tarifa.id}/`,
      tarifa
    )
    return data
  }

  async deleteServicoTarifa(tarifa) {
    const { data } = await axios.delete(
      `api/v1/patio/servicos-tarifa-${tarifa.tipoTarifa}/${tarifa.id}/`
    )
    return data
  }

  async getServicoAutorizadoTarifaEixo(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicos-autorizado-tarifa-eixo/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoAutorizadoTarifaEmbalagem(params) {
    try {
      const {
        data,
      } = await axios.get(
        `/api/v1/patio/servicos-autorizado-tarifa-embalagem/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoAutorizadoTarifaHora(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicos-autorizado-tarifa-hora/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoAutorizadoTarifaPeso(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicos-autorizado-tarifa-peso/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoAutorizadoTarifaSubProduto(params) {
    try {
      const {
        data,
      } = await axios.get(
        `/api/v1/patio/servicos-autorizado-tarifa-subproduto/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getServicoAutorizadoTarifa(tipoTarifa, params) {
    const { data } = await axios.get(
      `api/v1/patio/servicos-autorizado-tarifa-${tipoTarifa}/`,
      {
        params,
      }
    )

    return data
  }

  async addServicoAutorizadoTarifa(tarifa) {
    if (!tarifa.tipoTarifa) return

    const { data } = await axios.post(
      `api/v1/patio/servicos-autorizado-tarifa-${tarifa.tipoTarifa}/`,
      tarifa
    )
    return data
  }

  async editServicoAutorizadoTarifa(tarifa) {
    if (!tarifa.tipoTarifa) return

    const { data } = await axios.patch(
      `api/v1/patio/servicos-autorizado-tarifa-${tarifa.tipoTarifa}/${tarifa.id}/`,
      tarifa
    )
    return data
  }

  async deleteServicoAutorizadoTarifa(tarifa) {
    const { data } = await axios.delete(
      `api/v1/patio/servicos-autorizado-tarifa-${tarifa.tipoTarifa}/${tarifa.id}/`
    )
    return data
  }

  // servico caminhao
  async getServicosCaminhao() {
    const { data } = await axios.get('api/v1/patio/servicoCaminhao/')
    return data
  }

  async addServicoCaminhao(novo) {
    const { data } = await axios.post('api/v1/patio/servicoCaminhao/', novo)
    return data
  }

  async editServicoCaminhao(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/patio/servicoCaminhao/${id}/`,
      novo
    )
    return data
  }

  // status servico
  async getStatusServico() {
    const { data } = await axios.get('api/v1/patio/statusServico/')
    return data
  }

  async addStatusServico(novo) {
    const { data } = await axios.post('api/v1/patio/statusServico/', novo)
    return data
  }

  async editStatusServico(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/patio/statusServico/${id}/`,
      novo
    )
    return data
  }

  // tambores
  async getTambores() {
    const { data } = await axios.get('api/v1/patio/tambores/')
    return data
  }

  async addTambor(novo) {
    const { data } = await axios.post('api/v1/patio/tambores/', novo)
    return data
  }

  async editTambor(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/tambores/${id}/`, novo)
    return data
  }

  // tarifas
  async getTarifas() {
    const { data } = await axios.get('api/v1/patio/tarifas/')
    return data
  }

  async addTarifa(novo) {
    const { data } = await axios.post('api/v1/patio/tarifas/', novo)
    return data
  }

  async editTarifa(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/tarifas/${id}/`, novo)
    return data
  }

  // convenios / convênios
  async addConvenio(empresa, convenio) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/`,
        convenio
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editConvenio(empresa, convenio) {
    try {
      const { data } = await axios.patch(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/${convenio.public_id}/`,
        convenio
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getConvenios(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getConveniosByParams(empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async desativarConvenio(empresa, convenio) {
    try {
      await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/${convenio.public_id}/desativar/`
      )
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async ativarConvenio(empresa, convenio) {
    try {
      await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/${convenio.public_id}/ativar/`
      )
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async removerConvenio(empresa, convenio) {
    try {
      await axios.delete(
        `/api/v1/tenants/empresas/${empresa.public_id}/convenios/${convenio.public_id}/`
      )
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // terminais
  async getTerminal(params) {
    const { data } = await axios.get('api/v1/patio/terminais/', {
      params,
    })
    return data
  }

  async addTerminal(novo) {
    const { data } = await axios.post('api/v1/patio/terminais/', novo)
    return data
  }

  async editTerminal(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/terminais/${id}/`, novo)
    return data
  }

  async deleteTerminal(id) {
    try {
      await axios.delete(`api/v1/patio/terminais/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // tipo frete
  async getTiposFrete() {
    const { data } = await axios.get('api/v1/patio/tipoFrete/')
    return data
  }

  // tipo operação
  async getTipoOperacao() {
    const { data } = await axios.get('api/v1/patio/tipoOperacao/')
    return data
  }

  async addTipoFrete(novo) {
    const { data } = await axios.post('api/v1/patio/tipoFrete/', novo)
    return data
  }

  async editTipoFrete(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/tipoFrete/${id}/`, novo)
    return data
  }

  // periféricos

  async getPerifericos(params) {
    try {
      const { data } = await axios.get('api/v1/patio/periferico/', { params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addPeriferico(novo) {
    const { data } = await axios.post('api/v1/patio/periferico/', novo)
    return data
  }

  async editPeriferico(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/periferico/${id}/`, novo)
    return data
  }

  async deletePeriferico(id) {
    try {
      await axios.delete(`api/v1/patio/periferico/${id}`)
      return true
    } catch (error) {
      return false
    }
  }

  // etapas periféricos

  async getEtapaPeriferico(params) {
    try {
      const { data } = await axios.get('api/v1/patio/perifericoEtapa/', {
        params,
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addEtapaPeriferico(novo) {
    const { data } = await axios.post('api/v1/patio/perifericoEtapa/', novo)
    return data
  }

  async editEtapaPeriferico(novo) {
    const id = novo.id
    const { data } = await axios.patch(
      `api/v1/patio/perifericoEtapa/${id}/`,
      novo
    )
    return data
  }

  async deleteEtapaPeriferico(id) {
    try {
      await axios.delete(`api/v1/patio/perifericoEtapa/${id}`)
      return true
    } catch (error) {
      return false
    }
  }

  // veiculo
  async getVeiculos(params) {
    if (params.search) {
      params.placa_cavalo = params.search
      delete params.search
    }
    try {
      const { data } = await axios.get('api/v1/patio/veiculos/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getVeiculoParams(params) {
    const { data } = await axios.get('api/v1/patio/veiculos/', {
      params,
    })
    return data
  }

  async addVeiculo(novo) {
    try {
      const { data } = await axios.post('api/v1/patio/veiculos/', novo)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async editVeiculo(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/veiculos/${id}/`, novo)
    return data
  }

  async deleteVeiculo(id) {
    try {
      await axios.delete(`api/v1/patio/veiculos/${id}/`)
      return true
    } catch (err) {
      return false
    }
  }

  // veiculo
  async getRFIDs(veiculo, params) {
    try {
      const veiculo_id = veiculo.id
      const { data } = await axios.get(
        `api/v1/patio/veiculos/${veiculo_id}/rfids/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addRFID(veiculo, novo) {
    try {
      const veiculo_id = veiculo.id
      const { data } = await axios.post(
        `api/v1/patio/veiculos/${veiculo_id}/rfids/`,
        novo
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async editRFID(veiculo, novo) {
    try {
      const veiculo_id = veiculo.id
      const public_id = novo.public_id
      const { data } = await axios.patch(
        `api/v1/patio/veiculos/${veiculo_id}/rfids/${public_id}/`,
        novo
      )
      return data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteRFID(veiculo_id, public_id) {
    try {
      await axios.delete(
        `api/v1/patio/veiculos/${veiculo_id}/rfids/${public_id}/`
      )
      return true
    } catch (err) {
      return false
    }
  }

  // motorista
  async getMotorista() {
    try {
      const { data } = await axios.get('api/v1/motoristas/')
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addMotorista(novo) {
    const { data } = await axios.post('api/v1/motoristas/', novo)
    return data
  }

  async editMotorista(motorista) {
    const { data } = await axios.patch(
      `api/v1/motoristas/${motorista.public_id}/`,
      {
        user: motorista.user,
      }
    )
    return data
  }
  async updateDocumentosMotorista(motorista, docs) {
    const { data } = await axios.patch(
      `api/v1/motoristas/${motorista.public_id}/`,
      {
        documentos: docs,
      }
    )
    return data
  }
  async updateFotoMotorista(motorista, foto) {
    const { data } = await axios.patch(
      `api/v1/motoristas/${motorista.public_id}/`,
      {
        foto: foto,
      }
    )
    return data
  }
  async updateDocumentosVeiculo(veiculo, docs) {
    const { data } = await axios.patch(
      `api/v1/patio/veiculos/${veiculo.id}/documentos/`,
      {
        documentos: docs,
      }
    )
    return data
  }

  // navio
  async getNavios(params) {
    try {
      const { data } = await axios.get('api/v1/patio/navios/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addNavio(novo) {
    const { data } = await axios.post('api/v1/patio/navios/', novo)
    return data
  }

  async editNavio(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/navios/${id}/`, novo)
    return data
  }

  // cancelas
  async getCancelas() {
    const { data } = await axios.get('api/v1/patio/cancelas/')
    return data
  }

  async addCancela(novoServico) {
    const { data } = await axios.post('api/v1/patio/cancelas/', novoServico)
    return data
  }

  async editCancela(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(
      `api/v1/patio/cancelas/${id}/`,
      novoValor
    )
    return data
  }

  async getSequenciaAtendimentoParams(params) {
    const { data } = await axios.get('api/v1/patio/sequenciaatendimento/', {
      params,
    })
    return data
  }

  async addSenhasAtendimento(novoValor) {
    const { data } = await axios.post(
      'api/v1/patio/senhasatendimento/',
      novoValor
    )
    return data
  }

  async editSenhasAtendimento(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(
      `api/v1/patio/senhasatendimento/${id}/`,
      novoValor
    )
    return data
  }

  async deleteSenhasAtendimento(id) {
    try {
      await axios.delete(`api/v1/patio/senhasatendimento/${id}`)
      return true
    } catch (error) {
      return false
    }
  }
  // motivo_cancelamento
  async getMotivosCancelamentoParams(params) {
    try {
      const { data } = await axios.get('api/v1/patio/motivo_cancelamento/', {
        params,
      })

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getMotivosInativos(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/motivo_cancelamento/listagem_inativos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async addMotivoCancelamento(novoValor) {
    const { data } = await axios.post(
      'api/v1/patio/motivo_cancelamento/',
      novoValor
    )
    return data
  }

  async editMotivoCancelamento(novoValor) {
    const public_id = novoValor.public_id
    const { data } = await axios.patch(
      `api/v1/patio/motivo_cancelamento/${public_id}/`,
      novoValor
    )
    return data
  }

  async desativarMotivoCancelamento(public_id) {
    try {
      await axios.delete(
        `api/v1/patio/motivo_cancelamento/${public_id}/desativar/`
      )
      return true
    } catch (error) {
      return false
    }
  }

  async ativarMotivoCancelamento(public_id) {
    try {
      await axios.post(`api/v1/patio/motivo_cancelamento/${public_id}/ativar/`)
      return true
    } catch (error) {
      return false
    }
  }

  // blacklist
  async getBlacklistParams(params) {
    const { data } = await axios.get('api/v1/patio/blackList/', { params })
    return data
  }

  async addBlacklist(novoValor) {
    const { data } = await axios.post('api/v1/patio/blackList/', novoValor)
    return data
  }

  async editBlacklist(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(
      `api/v1/patio/blackList/${id}/`,
      novoValor
    )
    return data
  }

  async deleteBlacklist(id) {
    try {
      await axios.delete(`api/v1/patio/blackList/${id}`)
      return true
    } catch (error) {
      return false
    }
  }
  // blacklist
  async getTefsParams(params) {
    const { data } = await axios.get('api/v1/patio/tef/', { params })
    return data
  }

  async addTefs(novoValor) {
    const { data } = await axios.post('api/v1/patio/tef/', novoValor)
    return data
  }

  async editTefs(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(`api/v1/patio/tef/${id}/`, novoValor)
    return data
  }

  // carroceria
  async getCarrocerias() {
    try {
      const { data } = await axios.get('api/v1/patio/carrocerias/')
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addCarroceria(novo) {
    const { data } = await axios.post('api/v1/patio/carrocerias/', novo)
    return data
  }

  async editCarroceria(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(
      `api/v1/patio/carrocerias/${id}/`,
      novoValor
    )
    return data
  }

  async deleteCarroceria(id) {
    try {
      await axios.delete(`api/v1/patio/carrocerias/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // cores
  async getCores() {
    try {
      const { data } = await axios.get('api/v1/patio/cores/')
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addCor(novo) {
    const { data } = await axios.post('api/v1/patio/cores/', novo)
    return data
  }

  async editCor(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(`api/v1/patio/cores/${id}/`, novoValor)
    return data
  }

  async deleteCor(id) {
    try {
      await axios.delete(`api/v1/patio/cores/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // marcas
  async getMarcas() {
    try {
      const { data } = await axios.get('api/v1/patio/marcas/')
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addMarca(novo) {
    const { data } = await axios.post('api/v1/patio/marcas/', novo)
    return data
  }

  async editMarca(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(`api/v1/patio/marcas/${id}/`, novoValor)
    return data
  }

  async deleteMarca(id) {
    try {
      await axios.delete(`api/v1/patio/marcas/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // forma de pagamento
  async getFormasPagamento() {
    const { data } = await axios.get('api/v1/patio/formaspagamento/')
    return data
  }

  async getFormasPagamentoByParams(params) {
    const { data } = await axios.get('api/v1/patio/formaspagamento/', {
      params,
    })
    return data
  }

  async addFormaPagamento(novoServico) {
    const { data } = await axios.post(
      'api/v1/patio/formaspagamento/',
      novoServico
    )
    return data
  }

  async editFormaPagamento(novoValor) {
    const id = novoValor.id
    const { data } = await axios.patch(
      `api/v1/patio/formaspagamento/${id}/`,
      novoValor
    )
    return data
  }

  // modalidade
  async getModalidades(params = undefined) {
    try {
      const { data } = await axios.get('api/v1/patio/modalidades/', { params })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async addModalidade(novo) {
    const { data } = await axios.post('api/v1/patio/modalidades/', novo)
    return data
  }

  async editModalidade(novo) {
    const id = novo.id
    const { data } = await axios.patch(`api/v1/patio/modalidades/${id}/`, novo)
    return data
  }

  async deleteModalidade(id) {
    try {
      await axios.delete(`api/v1/patio/modalidades/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  async deleteEstacionamento(public_id, empresa) {
    try {
      await axios.delete(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${public_id}/`
      )
      return true
    } catch (err) {
      return false
    }
  }

  // patio
  async getSetor(setor, estacionamento, empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/${setor}`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getSetores(estacionamento, empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/`
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async getSetoresSimple(estacionamento, empresa, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/simple/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getVagasSimple(estacionamento, empresa, setor, params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/${setor}/vagas/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async addSetor(novoSetor, estacionamento, empresa) {
    const { data } = await axios.post(
      `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/`,
      {
        descricao: novoSetor.descricao,
        fila: novoSetor.fila,
        fornecedor: novoSetor.fornecedor
          ? novoSetor.fornecedor.public_id
          : null,
        terminal: novoSetor.terminal ? novoSetor.terminal.id : null,
        bonificada: novoSetor.bonificada,
        transportadora: novoSetor.transportadora
          ? novoSetor.transportadora
          : null,
        produto: novoSetor.produto ? novoSetor.produto.id : null,
        subProduto: novoSetor.subProduto ? novoSetor.subProduto.id : null,
        prefixo: novoSetor.prefixo,
        num_vagas: novoSetor.num_vagas,
      }
    )
    return data
  }
  async editSetor(novoValor, estacionamento, empresa) {
    const public_id = novoValor.public_id
    const { data } = await axios.put(
      `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/${public_id}/`,
      {
        descricao: novoValor.descricao,
        fila: novoValor.fila,
        fornecedor: novoValor.fornecedor
          ? novoValor.fornecedor.public_id
          : null,
        terminal: novoValor.terminal ? novoValor.terminal.id : null,
        bonificada: novoValor.bonificada,
        transportadora: novoValor.transportadora
          ? novoValor.transportadora
          : null,
        produto: novoValor.produto ? novoValor.produto.id : null,
        subProduto: novoValor.subProduto ? novoValor.subProduto.id : null,
        prefixo: novoValor.prefixo,
        num_vagas: novoValor.num_vagas,
      }
    )
    return data
  }

  async deleteSetor(public_id, estacionamento, empresa) {
    try {
      await axios.delete(
        `api/v1/tenants/tenants/${empresa}/estacionamentos/${estacionamento}/setores/${public_id}/`
      )
      return true
    } catch (err) {
      return false
    }
  }

  // Agendamento
  async addAgendamento(empresa, novoAgendamento) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/`,
        novoAgendamento
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async addAgendamentoAnexos(empresa, agendamento, anexos) {
    try {
      const {
        data,
      } = await axios.put(
        `/api/v1/tenants/empresas/${agendamento.proprietario.public_id}/agendamentos/${agendamento.public_id}/anexos/`,
        { anexos }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async gerarSenhasDaTriagem(empresa, agendamento, dados) {
    try {
      const { data } = await axios.post(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento.public_id}/gerar_senhas/`,
        dados
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAgendamento(empresa, params) {
    const {
      data,
    } = await axios.get(
      `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/`,
      { params }
    )
    return data
  }

  async getAllAgendamento(params) {
    const { data } = await axios.get(`/api/v1/tenants/agendamentos/`, {
      params,
    })
    return data
  }

  async getConsultaGerenciamentoServico(rfid) {
    const { data } = await axios.get(
      `/api/v1/patio/gerenciamento-servico/consulta/?rfid=${rfid}`
    )
    return data
  }

  async getAgendamentoGerenciamentoServico(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/gerenciamento-servico/${agendamento.public_id}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAgendamentoRelatorio(params) {
    const { data } = await axios.get(
      `/api/v1/patio/gerenciamento-servico/relatorio`,
      {
        params,
      }
    )
    return data
  }
  async getAgendamentoRelatorioOrigem(params) {
    const { data } = await axios.get(
      `/api/v1/patio/gerenciamento-servico/relatorio_origem`,
      {
        params,
      }
    )
    return data
  }

  async getAgendamentoRelatorioCancelados(params) {
    const { data } = await axios.get(
      `/api/v1/patio/gerenciamento-servico/relatorio_cancelados`,
      {
        params,
      }
    )
    return data
  }

  async getAgendamentoListagem(params) {
    const { data } = await axios.get(
      `/api/v1/patio/gerenciamento-servico/listagem/`,
      {
        params,
      }
    )
    return data
  }

  async getRelatorioServico(gate, params) {
    const {
      data,
    } = await axios.get(
      `/api/v1/tenants/gates/${params.gate}/relatorio_servico/`,
      { params }
    )
    return data
  }

  async getRelatorioServicoEixo(gate, params) {
    const {
      data,
    } = await axios.get(
      `/api/v1/tenants/gates/${params.gate}/relatorio_eixo/`,
      { params }
    )
    return data
  }

  async getAgendamentoHistorico(agendamento, empresa) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento}/historico/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAgendamentoHistoricoPause(agendamento, empresa) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento}/historico_pause/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getAgendamentoServicoTriado(agendamento) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicoTriado/?agendamento=${agendamento.public_id}`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async editAgendamento(agendamentoPublicId, novoAgendamento) {
    try {
      const { data } = await axios.put(
        `/api/v1/tenants/empresas/${novoAgendamento.proprietario}/agendamentos/${agendamentoPublicId}/`,
        novoAgendamento
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async trocarCotaAgendamento(novoAgendamento) {
    try {
      const { data } = await axios.put(
        `/api/v1/tenants/empresas/${novoAgendamento.proprietario.public_id}/agendamentos/${novoAgendamento.public_id}/trocar_cota/`,
        novoAgendamento
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async deleteAgendamento(empresa, agendamento) {
    try {
      const { data } = await axios.delete(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento.public_id}`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // Agendamento Etapa

  async addAgendamentoEtapa(novoAgendamentoEtapa) {
    const novo = novoAgendamentoEtapa
    const { data } = await axios.post(`/api/v1/patio/agendamentoEtapa/`, novo)
    return data
  }

  async getAgendamentoEtapa(id) {
    const { data } = await axios.get(`/api/v1/patio/agendamentoEtapa/${id}`)
    return data
  }

  async editAgendamentoEtapa(novoAgendamento) {
    const id = novoAgendamento.id
    const { data } = await axios.patch(
      `/api/v1/patio/agendamentoEtapa/${id}`,
      novoAgendamento
    )
    return data
  }

  async deleteAgendamentoEtapa(id) {
    try {
      await axios.delete(`/api/v1/patio/agendamentoEtapa/${id}`)
      return true
    } catch (err) {
      return false
    }
  }

  // Agendamento Servico

  async addAgendamentoServico(agendamentoPublicId, novoAgendamentoServico) {
    const novo = novoAgendamentoServico
    const { data } = await axios.post(
      `/api/v1/tenants/agendamentos/${agendamentoPublicId}/agendamentoServicos/`,
      novo
    )
    return data
  }

  async getAgendamentoServico(agendamentoPublicId, id) {
    const { data } = await axios.get(
      `/api/v1/tenants/agendamentos/${agendamentoPublicId}/agendamentoServicos/${id}`
    )
    return data
  }

  async editAgendamentoServico(agendamentoPublicId, novoAgendamento) {
    const id = novoAgendamento.id
    const { data } = await axios.patch(
      `/api/v1/tenants/agendamentos/${agendamentoPublicId}/agendamentoServicos/${id}`,
      novoAgendamento
    )
    return data
  }

  async deleteAgendamentoServico(agendamentoPublicId, id) {
    try {
      await axios.delete(
        `/api/v1/tenants/agendamentos/${agendamentoPublicId}/agendamentoServicos/${id}`
      )
      return true
    } catch (err) {
      return false
    }
  }

  // Suspensao
  async getSuspensoes(empresa) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/tenants/${empresa.public_id}/suspensoes/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async addSuspensao(empresa, suspensao) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa.public_id}/suspensoes/`,
        suspensao
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async editSuspensao(empresa, suspensao) {
    try {
      const { data } = await axios.put(
        `api/v1/tenants/tenants/${empresa.public_id}/suspensoes/${suspensao.public_id}/`,
        suspensao
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async removerSuspensao(empresa, suspensao) {
    try {
      const { data } = await axios.delete(
        `api/v1/tenants/tenants/${empresa.public_id}/suspensoes/${suspensao.public_id}/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  // tipo controle
  async getTiposControle() {
    const { data } = await axios.get('api/v1/patio/tipoControle/')
    return data
  }

  async getServicosAutorizadosFornecedor(fornecedor, empresa) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/gates/${empresa.public_id}/fornecedores/${fornecedor}/servico-autorizado/`
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getServicosAutorizadosFornecedorParams(params) {
    try {
      const { data } = await axios.get(`/api/v1/tenants/servico-autorizado/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async getServicoTarifaEixo(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/servicos-tarifa-eixo/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoTarifaEmbalagem(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicos-tarifa-embalagem/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoTarifaHora(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/servicos-tarifa-hora/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoTarifaPeso(params) {
    try {
      const { data } = await axios.get(`/api/v1/patio/servicos-tarifa-peso/`, {
        params,
      })
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
  async getServicoTarifaSubProduto(params) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/servicos-tarifa-subproduto/`,
        {
          params,
        }
      )
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async addTriagem(empresa, novoAgendamento, agendamentoID) {
    const { data } = await axios.put(
      `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamentoID}/`,
      novoAgendamento
    )
    return data
  }

  async verificarAgendamentoEmAbertoMotorista(agendamento) {
    try {
      let date = new Date()
      if (agendamento.programacao_unidade) {
        date = agendamento.programacao_unidade.data_inicio
      }
      if (agendamento.programacao_fornecedor) {
        date =
          agendamento.programacao_fornecedor.programacao_unidade.data_inicio
      }
      if (agendamento.programacao_regiao) {
        date =
          agendamento.programacao_regiao.programacao_fornecedor
            .programacao_unidade.data_inicio
      }
      if (agendamento.programacao_cliente) {
        date =
          agendamento.programacao_cliente.programacao_regiao
            .programacao_fornecedor.programacao_unidade.data_inicio
      }

      let public_id = null
      if (agendamento.public_id) {
        public_id = agendamento.public_id
      }
      const { data } = await axios.post(`api/v1/patio/agendamento/motorista/`, {
        data: new Date(date).toISOString(),
        tipo_operacao: agendamento.tipo_operacao.id,
        gate: agendamento.gate.public_id,
        motorista: agendamento.motorista.public_id,
        public_id: public_id,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async verificarMotivoCancelamento(agendamento) {
    try {
      const { data } = await axios.post(
        `api/v1/patio/agendamento/motivo_cancelamento/`,
        {
          gate: agendamento.gate.public_id,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async verificarBlackList(agendamento) {
    try {
      const { data } = await axios.post(`api/v1/patio/agendamento/blacklist/`, {
        motorista: agendamento.motorista.user.cpf,
        transportadora: agendamento.transportadora.cnpj,
        gate: agendamento.gate.public_id,
        veiculo: agendamento.veiculo.placa_cavalo,
        fornecedor: agendamento.fornecedor.public_id,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async verificaBiometriaCadastrada(agendamento) {
    try {
      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/verificabiometria`,
        {
          cpf: agendamento.motorista.user.cpf,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
          },
        }
      )
      console.log(data)

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async verificarNumeroNfe(chave_acesso, public_id) {
    try {
      const { data } = await axios.post(
        `api/v1/patio/agendamento/validaNumeroNfe/`,
        {
          chave_acesso: chave_acesso,
          public_id: public_id,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async verificarAgendamentoEmAbertoVeiculo(agendamento) {
    try {
      let public_id = null
      if (agendamento.public_id) {
        public_id = agendamento.public_id
      }
      const { data } = await axios.post(`api/v1/patio/agendamento/veiculo/`, {
        tipo_operacao: agendamento.tipo_operacao.id,
        public_id: public_id,
        gate: agendamento.gate.public_id,
        veiculo: agendamento.veiculo.id,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getRelatorioDetalhado(gate, params) {
    const {
      data,
    } = await axios.get(
      `/api/v1/tenants/gates/${params.gate}/relatorio_detalhado/`,
      { params }
    )
    return data
  }

  async getRelatorioProdutoEixo(gate, params) {
    const { data } = await axios.get(
      `/api/v1/tenants/gates/${params.gate}/produto_eixo/`,
      {
        params,
      }
    )
    return data
  }

  async getResumoPatio(gate, params) {
    const {
      data,
    } = await axios.get(
      `api/v1/tenants/empresas/${params.gate}/agendamentos/resumo_patio/`,
      { params }
    )
    return data
  }

  async getResumoTerminal(gate, params) {
    const {
      data,
    } = await axios.get(
      `api/v1/tenants/empresas/${params.gate}/agendamentos/resumo_patio_terminal/`,
      { params }
    )
    return data
  }

  async getResumoSubProduto(gate, params) {
    const {
      data,
    } = await axios.get(
      `api/v1/tenants/empresas/${params.gate}/agendamentos/resumo_sub_produto/`,
      { params }
    )
    return data
  }

  async getRelatorioTotal(gate, params) {
    const {
      data,
    } = await axios.get(
      `/api/v1/tenants/gates/${params.gate}/relatorio_total/`,
      { params }
    )
    return data
  }

  async getLaudoLimpezaAgendamento(empresa, agendamento) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento.public_id}/laudo_limpeza`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // // Consultar janela de descarga
  async getDataJanelaDescargaTesc(agendamento) {
    try {
      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/teste/validarjanela`,
        {
          public_id: agendamento.public_id,
        },
        {
          headers: {
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
            'Content-Type': 'application/json',
          },
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // Confirma saida do patio tesc
  async confirmaSaidaPatioTesc(empresa, agendamento) {
    try {
      let placas = []

      placas.push({ placa: agendamento.veiculo.placa_carreta_1, sequencia: 1 })

      if (agendamento.veiculo.placa_carreta_2 !== '') {
        placas.push({
          placa: agendamento.veiculo.placa_carreta_2,
          sequencia: 2,
        })
      }

      let listanf = []

      for (let item of agendamento.pedidos_agendados) {
        listanf.push({
          numero: item.numero_nfe,
          serie: item.numero_serie,
          chave: item.chave_acesso,
          dataemissao: moment(item.dataEmissaoNf).format('YYYY/MM/DD HH:mm:ss'),
          pesodeclarado: item.quantidade_agendada * 1000,
          pesorateado: item.quantidade_agendada * 1000,
          cnpjemissor: agendamento.remetente.cnpj,
        })
      }

      const now = new Date()

      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/cadastroagendamento/`,
        {
          datainicio: moment(agendamento.triado_em).format(
            'YYYY/MM/DD HH:mm:ss'
          ),
          datafim: moment(now).format('YYYY/MM/DD HH:mm:ss'),
          codigoexterno: agendamento.public_id,
          carga: 'granel',
          motivacao: agendamento.tipo_operacao.descricao,
          agendamentos: [
            {
              placadianteira: agendamento.veiculo.placa_cavalo,
              placatraseira: placas,
              tag: agendamento.access_credential,
              cracha: agendamento.access_credential,
              cpfmotorista: agendamento.motorista.user.cpf,
              listanf: listanf,
            },
          ],
        },
        {
          headers: {
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
            'Content-Type': 'application/json',
          },
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // Confirma chegada do patio tesc
  async confirmaChegadaPatioTesc(empresa, agendamento) {
    try {
      let placas = []

      placas.push({ placa: agendamento.veiculo.placa_carreta_1, sequencia: 1 })

      if (agendamento.veiculo.placa_carreta_2 !== '') {
        placas.push({
          placa: agendamento.veiculo.placa_carreta_2,
          sequencia: 2,
        })
      }

      let listanf = []

      for (let item of agendamento.pedidos_agendados) {
        listanf.push({
          numero: item.numero_nfe,
          serie: item.numero_serie,
          chave: item.chave_acesso,
          dataemissao: moment(item.dataEmissaoNf).format('YYYY/MM/DD HH:mm:ss'),
          pesodeclarado: item.quantidade_agendada * 1000,
          pesorateado: item.quantidade_agendada * 1000,
          cnpjemissor: agendamento.remetente.cnpj,
        })
      }

      const now = new Date()

      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/validandoagendamento/`,
        {
          datainicio: moment(now).format('YYYY/MM/DD HH:mm:ss'),
          datafim: '',
          codigoexterno: agendamento.public_id,
          carga: 'granel',
          motivacao: agendamento.tipo_operacao.descricao,
          agendamentos: [
            {
              placadianteira: agendamento.veiculo.placa_cavalo,
              placatraseira: placas,
              tag: agendamento.access_credential,
              cracha: agendamento.access_credential,
              cpfmotorista: agendamento.motorista.user.cpf
                .replaceAll('.', '')
                .replace('-', ''),
              listanf: listanf,
            },
          ],
        },
        {
          headers: {
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
            'Content-Type': 'application/json',
          },
        }
      )

      console.log(data)

      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // Confirma saida do patio Iport
  async confirmaSaidaPatioIport(empresa, agendamento) {
    try {
      let placas = []

      placas.push({ placa: agendamento.veiculo.placa_carreta_1, sequencia: 1 })

      if (agendamento.veiculo.placa_carreta_2 !== '') {
        placas.push({
          placa: agendamento.veiculo.placa_carreta_2,
          sequencia: 2,
        })
      }

      let listanf = []

      for (let item of agendamento.pedidos_agendados) {
        listanf.push({
          numero: item.numero_nfe,
          serie: item.numero_serie,
          chave: item.chave_acesso,
          dataemissao: moment(item.dataEmissaoNf).format('YYYY/MM/DD HH:mm:ss'),
          pesodeclarado: item.quantidade_agendada * 1000,
          pesorateado: item.quantidade_agendada * 1000,
          cnpjemissor: agendamento.remetente.cnpj,
        })
      }

      const now = new Date()

      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/cadastroagendamento/`,
        {
          datainicio: moment(agendamento.triado_em).format(
            'YYYY/MM/DD HH:mm:ss'
          ),
          datafim: moment(now).format('YYYY/MM/DD HH:mm:ss'),
          codigoexterno: agendamento.public_id,
          carga: 'granel',
          motivacao: agendamento.tipo_operacao.descricao,
          agendamentos: [
            {
              placadianteira: agendamento.veiculo.placa_cavalo,
              placatraseira: placas,
              tag: agendamento.access_credential,
              cracha: agendamento.access_credential,
              cpfmotorista: agendamento.motorista.user.cpf,
              listanf: listanf,
            },
          ],
        },
        {
          headers: {
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
            'Content-Type': 'application/json',
          },
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // Confirma chegada do patio Iport
  async confirmaChegadaPatioIport(empresa, agendamento) {
    try {
      console.log(empresa)
      console.log(agendamento)

      // let headers = {
      //   "content-type": "application/x-www-form-urlencoded",
      //   "Authorization": "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //   'Access-Control-Allow-Origin' : '*',
      // }

      // var body = new FormData();
      // body.append('Username', '556b81d0-7257-440a-97e5-e973fbfd93e3');
      // body.append('grant_type', 'password');
      // body.append('scope', 'offline_access');
      // body.append('password', '82d75afb-0467-454f-9512-970f46fc7cc3');

      // const { response } = await axios.post(
      //   "https://autenticacao.terminalsc.com.br/identity/connect/token/",
      //   {
      //     data: body
      //   },
      //   {
      //     headers,
      //   },
      // )

      const data = {
        Username: '556b81d0-7257-440a-97e5-e973fbfd93e3',
        grant_type: 'password',
        scope: 'offline_access',
        password: '82d75afb-0467-454f-9512-970f46fc7cc3',
      }

      const { response } = await axios(
        'https://autenticacao.terminalsc.com.br/identity/connect/token/',
        {
          method: 'POST',
          data: data,
          mode: 'no-cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: 'Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=',
          },
          withCredentials: true,
          credentials: 'same-origin',
        }
      )
      // const FormData = require('form-data');

      // const form = new FormData();
      // form.append("Username", "556b81d0-7257-440a-97e5-e973fbfd93e3");
      // form.append("grant_type", "password");
      // form.append("scope", "offline_access");
      // form.append("password", "82d75afb-0467-454f-9512-970f46fc7cc3");

      // const { response } = axios.post('https://autenticacao.terminalsc.com.br/identity/connect/token', form, { headers: {
      //          "Content-Type" : "application/x-www-form-urlencoded;charset=UTF-8",
      //          "Authorization" : "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //      } })

      // const { response } = axios.post({
      //   url: "https://autenticacao.terminalsc.com.br/identity/connect/token",
      //   headers: {
      //       "Content-Type" : "application/x-www-form-urlencoded;charset=UTF-8",
      //       "Authorization" : "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //   },
      //   data: queryString.stringify({
      //     Username : "556b81d0-7257-440a-97e5-e973fbfd93e3",
      //     grant_type : "password",
      //     scope : "offline_access",
      //     password : "82d75afb-0467-454f-9512-970f46fc7cc3"
      //   }),
      // })
      // .then(function(response) {
      //   console.log(response);
      // })
      // .catch(error => console.error(error));

      // let data = new FormData();
      // data.append("Username", "556b81d0-7257-440a-97e5-e973fbfd93e3");
      // data.append("grant_type", "password");
      // data.append("scope", "offline_access");
      // data.append("password", "82d75afb-0467-454f-9512-970f46fc7cc3");

      // const { requestToken } = await axios.post(`https://autenticacao.terminalsc.com.br/identity/connect/token`,
      //     data,
      //     {
      //     headers: {
      //         "Content-Type": "application/x-www-form-urlencoded",
      //         "Authorization": "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //     }
      // })
      // const { requestToken } = await axios.post(`https://autenticacao.terminalsc.com.br/identity/connect/token`, new URLSearchParams({
      //     username: 'abcd', //gave the values directly for testing
      //     password: '1235!',
      //     client_id: 'user-client'
      //   }),{
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Authorization": "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //     }
      //   }
      // )

      // console.log(requestToken)

      // const form = new URLSearchParams();
      // form.append("Username", "556b81d0-7257-440a-97e5-e973fbfd93e3");
      // form.append("grant_type", "password");
      // form.append("scope", "offline_access");
      // form.append("password", "82d75afb-0467-454f-9512-970f46fc7cc3");

      // const { requestToken } = await axios.post(
      //   `https://autenticacao.terminalsc.com.br/identity/connect/token`,
      //   form,
      //   {
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Authorization": "Basic aVBvcnRTb2x1dGlvbnNfQXBpX1BhZHJhbzpzZWNyZXQ=",
      //     },
      //   }
      // )

      // console.log(requestToken)
      // responseToken = json.loads(requestToken.content)

      // headers = {
      //     "Authorization": str("Bearer " + responseToken["access_token"]),
      //     "Content-Type": "application/x-www-form-urlencoded",
      // }

      // let placas = []

      // placas.push(
      //     {"placa": agendamento.veiculo.placa_carreta_1, "sequencia": 1}
      // )

      // if (agendamento.veiculo.placa_carreta_2 !== ""){
      //   placas.push(
      //     {"placa": agendamento.veiculo.placa_carreta_2, "sequencia": 2}
      //   )
      // }

      // let listanf = []

      // for (let item of agendamento.pedidos_agendados)
      // {
      //     listanf.push(
      //       {
      //           "numero": item.numero_nfe,
      //           "serie": item.numero_serie,
      //           "chave": item.chave_acesso,
      //           "dataemissao":
      //               moment(item.dataEmissaoNf).format('YYYY/MM/DD HH:mm:ss')
      //           ,
      //           "pesodeclarado" : (item.quantidade_agendada * 1000),
      //           "pesorateado" : (item.quantidade_agendada * 1000),
      //           "cnpjemissor" : agendamento.remetente.cnpj
      //       }
      //     )
      // }

      // const now = new Date()

      // const { data } = await axios.post(
      //   `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/cadastroagendamento/`,
      //     {
      //       "datainicio": moment(now).format('YYYY/MM/DD HH:mm:ss'),
      //       "datafim": "",
      //       "codigoexterno": agendamento.public_id,
      //       "carga": "granel",
      //       "motivacao": agendamento.tipo_operacao.descricao,
      //       "agendamentos": [
      //           {
      //               "placadianteira": agendamento.veiculo.placa_cavalo,
      //               "placatraseira": placas,
      //               "tag": agendamento.access_credential,
      //               "cracha": agendamento.access_credential,
      //               "cpfmotorista": agendamento.motorista.user.cpf,
      //               "listanf": listanf,
      //           }
      //       ],
      //   },
      //   {
      //     headers: {
      //       "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA",
      //       "Content-Type": "application/json",
      //     },
      //   }
      // )
      return Promise.resolve(response)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // valida agendamento tesc
  async validaAgendamentoTesc(tenant, agendamento) {
    try {
      let placas = []

      placas.push({ placa: agendamento.veiculo.placa_carreta_1, sequencia: 1 })

      if (agendamento.veiculo.placa_carreta_2 !== '') {
        placas.push({
          placa: agendamento.veiculo.placa_carreta_2,
          sequencia: 2,
        })
      }

      let listanf = []
      for (let item of agendamento.pedidos_agendados) {
        listanf.push({
          numero: item.numero_nfe,
          serie: item.numero_serie,
          chave: item.chave_acesso,
          dataemissao: moment(item.dataEmissaoNf).format('YYYY/MM/DD HH:mm:ss'),
          pesodeclarado: item.quantidade_agendada * 1000,
          pesorateado: item.quantidade_agendada * 1000,
          cnpjemissor: agendamento.remetente.cnpj,
        })
      }

      const now = new Date()

      const { data } = await axios.post(
        `https://core.apipass.com.br/api/202d4c59-32e6-4003-8e3c-ea399493f049/producao/validandoagendamento/`,
        {
          datainicio: moment(now).format('YYYY/MM/DD HH:mm:ss'),
          datafim: '',
          codigoexterno: agendamento.public_id,
          carga: 'granel',
          motivacao: agendamento.tipo_operacao.descricao,
          agendamentos: [
            {
              placadianteira: agendamento.veiculo.placa_cavalo,
              placatraseira: placas,
              tag: agendamento.access_credential,
              cracha: agendamento.access_credential,
              cpfmotorista: agendamento.motorista.user.cpf,
              listanf: listanf,
            },
          ],
        },
        {
          headers: {
            Authorization:
              'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRlIjoiUlQiLCJpYXQiOjE2Nzg5NjUxMjUsImV4cCI6ODY0MDAwMTY3ODg3ODcyNX0.sdU74BpKe07j00TqjF8HgRUift_O8S4w2NohQ46ttpA',
            'Content-Type': 'application/json',
          },
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async addLaudoItemCheckList(novoValor) {
    const { data } = await axios.post(
      'api/v1/patio/laudo_limpeza_itens_check_list/',
      novoValor
    )
    return data
  }

  async editLaudoItemCheckList(novoValor) {
    const id = novoValor.id
    const { data } = await axios.put(
      `api/v1/patio/laudo_limpeza_itens_check_list/${id}/`,
      novoValor
    )
    return data
  }

  // itens_laudo_check_list
  async getItensLaudoCheckListParams(params) {
    try {
      const { data } = await axios.get('api/v1/patio/itens_laudo_check_list/', {
        params,
      })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getItensLaudoCheckListInativos(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/itens_laudo_check_list/listagem_inativos/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async addItemLaudoCheckList(novoValor) {
    const { data } = await axios.post(
      'api/v1/patio/itens_laudo_check_list/',
      novoValor
    )
    return data
  }
  async desativarItemLaudoCheckList(public_id) {
    try {
      await axios.delete(
        `api/v1/patio/itens_laudo_check_list/${public_id}/desactive/`
      )
      return true
    } catch (error) {
      return false
    }
  }

  async ativarItemLaudoCheckList(public_id) {
    try {
      await axios.post(
        `api/v1/patio/itens_laudo_check_list/${public_id}/active/`
      )
      return true
    } catch (error) {
      return false
    }
  }

  async getItensLaudoCheckList(empresa, agendamento) {
    try {
      const { data } = await axios.get(
        `api/v1/tenants/empresas/${agendamento.gate.public_id}/agendamentos/${agendamento.public_id}/itens_laudo_check_list`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getGates(empresa, params, supervisorio) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/tenants/${empresa}/gates-service/`,
        {
          params,
        }
      )
      if (!supervisorio) {
        return Promise.resolve({
          results: [
            ...data.map((item) => {
              return { ...item }
            }),
          ],
        })
      }
      const _base = {
        status_atual: 'Aguardando comunicação',
        mensagem: 'Aguardando comunicação',
        display: '--',
        placa: 'XXX-XXXX',
        lado_a: {
          cancela: 0,
          sensores: [
            { status: 0, label: 'Sensor trasseiro' },
            { status: 0, label: 'Sensor dianteiro' },
          ],
        },
        peso_balanca: 0,
        lado_b: {
          cancela: 0,
          sensores: [
            { status: 0, label: 'Sensor dianteiro' },
            { status: 0, label: 'Sensor trasseiro' },
          ],
        },
        cameras: [
          {
            status: 0,
            label: 'Camera 1',
            id: '1abc',
            url: '',
          },
          {
            status: 0,
            label: 'Camera 2',
            id: '2abc',
            url: '',
          },
        ],
      }
      const _data = {
        results: [
          ...data.map((item) => {
            return { ...item, ..._base }
          }),
          // {
          //   public_id: 'UUID1',
          //   descricao: 'Teste 1',
          //   sentido: 'Entrada',
          //   ..._base,
          // },
          // {
          //   public_id: 'UUID2',
          //   descricao: 'Teste 2',
          //   sentido: 'Saida',
          //   ..._base,
          // },
          // {
          //   public_id: 'UUID3',
          //   descricao: 'Teste 3',
          //   sentido: 'Entrada/Saida',
          //   ..._base,
          // },
          // {
          //   public_id: 'UUID4',
          //   descricao: 'Teste 4',
          //   sentido: 'Entrada',
          //   ..._base,
          // },
        ],
      }
      console.log(_data)
      return Promise.resolve(_data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async pesar(empresa, gate_service, pesagem) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa}/gates-service/${gate_service}/send_weight/`,
        pesagem
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async editarPesagem(pesagem) {
    try {
      if (pesagem.agendamento) delete pesagem.agendamento
      if (pesagem.sessao) delete pesagem.sessao
      const { data } = await axios.put(
        `/api/v1/patio/pesagem/${pesagem.public_id}/`,
        pesagem
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async deletePesagem(pesagem) {
    try {
      const { data } = await axios.delete(
        `/api/v1/patio/pesagem/${pesagem.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getJustificativas(empresa) {
    try {
      const { data } = await axios.get(
        `/api/v1/tenants/empresas/${empresa.public_id}/justificativa`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async ocrManual(empresa, gate_service, ocrManual) {
    try {
      const { data } = await axios.post(
        `api/v1/tenants/tenants/${empresa}/gates-service/${gate_service}/open_session/`,
        ocrManual
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getLogIntegracao(log_integracao) {
    try {
      const { data } = await axios.get(
        `/api/v1/patio/log_integracao/${log_integracao.public_id}/`
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async atualizarAgendamento(empresa, agendamento) {
    try {
      const { data } = await axios.patch(
        `/api/v1/tenants/empresas/${empresa.public_id}/agendamentos/${agendamento.public_id}/atualizar/`,
        {
          ...agendamento,
        }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new PatioApi()
