import gatesApi from '@/services/tenants/gates'
import contratosApi from '@/services/tenants/contratos'
import patioApi from '@/services/patio'
import Perfil from '@/utils/perfil'
import motoristasApi from '@/services/tenants/motoristas'
import moment from 'moment'
import { BRString2datetimeIso } from '@/mixins/convertion'
import { cleanJson } from '@/utils'

const reset = ({ commit }) => {
  commit('RESET_STATE')
}

const gerarSenhasDaTriagem = async (
  { rootState },
  { agendamento, dados, empresa = rootState.auth.empresaAtual }
) => {
  try {
    const data = await patioApi.gerarSenhasDaTriagem(
      empresa,
      agendamento,
      dados
    )
    return Promise.resolve(data)
  } catch (e) {
    return Promise.reject(e)
  }
}

const atualizaTiposFreteAgendamento = async ({ commit }, gate) => {
  const tipos_frete = await gatesApi.getTiposFrete(gate)
  commit('ATUALIZA_TIPOS_FRETE', tipos_frete)
}

const atualizaFornecedoresAgendamento = async ({ commit }, gate) => {
  const fornecedores = await gatesApi.getFornecedores(gate)
  commit('ATUALIZA_FORNECEDORES', fornecedores)
}

const atualizaClientesAgendamento = async ({ commit }, fornecedor) => {
  const clientes = await contratosApi.getClientesSimple(fornecedor)
  commit('ATUALIZA_CLIENTES', clientes)
}
const buscaClienteAgendamento = async (_, { empresa, cliente }) => {
  try {
    const _cliente = await contratosApi.getCliente(
      empresa.public_id,
      cliente.public_id
    )
    return Promise.resolve(_cliente)
  } catch (error) {
    return Promise.reject(error)
  }
}
const buscaClientesAgendamento = async ({ commit }, params) => {
  const fornecedor = params.fornecedor
  delete params.fornecedor
  const clientes = await contratosApi.getClientesSimple(fornecedor, params)
  commit('ATUALIZA_CLIENTES', clientes)
}

const atualizaTransportadorasAgendamento = async ({ commit }, empresa) => {
  const tansportadoras = await contratosApi.getTransportadoras(empresa)
  commit('ATUALIZA_TRANSPORTADORAS', tansportadoras)
}

const atualizaGatesAgendamento = async (
  { commit, rootState },
  empresa = rootState.auth.empresaAtual
) => {
  const { possible_gates } = await contratosApi.getPossiveisGates(
    empresa.public_id
  )
  commit('ATUALIZA_GATES', possible_gates)
}

const atualizaTerminaisAgendamento = async ({ commit }, fornecedor) => {
  const terminais = await contratosApi.getContratos(fornecedor, {
    perfil: Perfil.Terminal,
  })
  commit('ATUALIZA_TERMINAIS', terminais)
}
const atualizaEstacionamentoAgendamento = async ({ commit }, gate) => {
  const estacionamentos = await patioApi.getEstacionamentos(gate)
  commit('ATUALIZA_ESTACIONAMENTOS', estacionamentos)
}
const atualizaSetoresAgendamento = async (
  { commit },
  { gate, estacionamento, params }
) => {
  const setores = await patioApi.getSetoresSimple(
    estacionamento.public_id,
    gate.public_id,
    params
  )
  commit('ATUALIZA_SETORES', setores)
}

const atualizaVagasAgendamento = async (
  { commit },
  { gate, estacionamento, setor, params }
) => {
  const vagas = await patioApi.getVagasSimple(
    estacionamento.public_id,
    gate.public_id,
    setor.public_id,
    params
  )
  commit('ATUALIZA_VAGAS', vagas)
}

const concatTransportadoras = async ({ commit }, empresaPublicID) => {
  const transportadoras = await contratosApi.getTransportadorasSimple(
    empresaPublicID
  )
  commit('CONCAT_TRANSPORTADORAS', transportadoras)
}

const updateAtributoAgendamento = ({ commit }, payload) => {
  commit('ATUALIZA_AGENDAMENTO', { [payload.nomeAtributo]: payload.valor })
}

const updateAtributo = ({ commit }, payload) => {
  commit('ATUALIZA', { [payload.nomeAtributo]: payload.valor })
}

const buscaVeiculo = async ({ commit, dispatch }, params) => {
  // let cadastroCompleto = false
  // if (params.cadastroCompleto) {
  //   cadastroCompleto = params.cadastroCompleto
  //   delete params.cadastroCompleto
  // }

  if (params) {
    const veiculos = await patioApi.getVeiculoParams(params)
    // const owner = params.owner || null
    if (veiculos.length) {
      // let veiculo
      // if (owner) {
      //   veiculo = veiculos.find((i) => i.proprietario.public_id == owner)
      // } else {
      //   veiculo = veiculos[0]
      // }
      const [veiculo] = veiculos
      console.log(veiculo)
      // if (!cadastroCompleto) {
      if (veiculo.motorista) {
        dispatch('informacoesMotorista', veiculo.motorista.user.cpf)
        // commit('ATUALIZA_AGENDAMENTO', { motorista: veiculo.motorista })
      }
      // }
      if (veiculo.conjunto_atual) {
        commit('ATUALIZA_AGENDAMENTO', { conjunto: veiculo.conjunto_atual })
      }
      commit('ATUALIZA_AGENDAMENTO', { veiculo })
    }
    return veiculos
  }
}

const informacoesMotorista = async ({ commit, rootState }, cpfMotorista) => {
  const empresaAtual = rootState.auth.empresaAtual
  const motoristas = await motoristasApi.getMotorista(empresaAtual, {
    search: cpfMotorista,
  })

  if (motoristas.length) {
    const [motorista] = motoristas

    commit('ATUALIZA_AGENDAMENTO', { motorista: motorista })

    return motorista
  }
}
const enviarAgendamento = async ({ state, rootState }, triagem = false) => {
  const empresaAtual = rootState.auth.empresaAtual
  let todosOsPedidos = []
  const is_default = ['NF', 'OC', 'VR', 'TI', 'TE'].includes(
    state.agendamento.tipo_documento
  )
  if (is_default) {
    const pedidosACriar = state.agendamento.pedidos.filter(
      (p) => p.criar && !p.public_id
    )
    const novosPedidos = pedidosACriar.map((p) => ({
      ...p,
      armazem: null,
      quantidade: triagem ? p.quantidade : p.quantidade.replace('.', '') / 1000,
      proprietario: state.agendamento.gate.public_id,
      gate: state.agendamento.gate.public_id,
      cliente: state.agendamento.cliente.public_id,
      fornecedor: state.agendamento.fornecedor.public_id,
      transportadora: state.agendamento.transportadora.public_id,
      tipoFrete: state.agendamento.tipo_frete.id,
      terminal: state.agendamento.terminal.public_id,
      produto: p.produto.id,
      subProduto: p.subProduto.id,
      embalagem: p.embalagem.id,
      criacao_automatica: true,
    }))

    const extraInfoByNumeroPedido = pedidosACriar.reduce((acc, p) => {
      if (acc[p.numero_pedido] === undefined) {
        acc[p.numero_pedido] = {
          armazem: p.armazem ? p.armazem.public_id : null,
          numero_serie: p.numero_serie,
          numero_nfe: p.numero_nfe,
          dataEmissaoNf: p.dataEmissaoNf
            ? moment(p.dataEmissaoNf).format('yyyy-MM-DD 00:00:00')
            : '',
          chave_acesso: p.chave_acesso,
          documentos: p.documentos
            ? p.documentos.map((i) => i.attachment_key)
            : [],
        }
      }
      return acc
    }, {})
    let pedidos = []

    if (novosPedidos.length > 0) {
      pedidos = await patioApi.addPedido(novosPedidos)
    }
    todosOsPedidos = pedidos
      .map((p) => ({
        // Os que foram criados.
        pedido: p.public_id,
        quantidade: p.quantidade,
        documentos: p.documentos
          ? p.documentos.map((i) => i.attachment_key)
          : [],
        ...extraInfoByNumeroPedido[p.numero_pedido],
      }))
      .concat(
        // Os que não foram criados, já existiam.
        state.agendamento.pedidos
          .filter((p) => !p.criar || p.public_id)
          .map((p) => ({
            pedido: p.public_id,
            quantidade: p.quantidade,
            documentos: p.documentos
              ? p.documentos.map((i) => i.attachment_key)
              : [],
            ...extraInfoByNumeroPedido[p.numero_pedido],
          }))
      )
  } else {
    if (!is_default) {
      // TO - DO
      // Arrumar esses valores default.
      state.agendamento.remetente = {
        public_id: 'f208501b-009d-427f-8b41-e107ed763b7d',
      }
      state.agendamento.destinatario = {
        public_id: 'f208501b-009d-427f-8b41-e107ed763b7d',
      }
    }
  }
  let pesagens = state.agendamento.pesagens
    .map((i) => {
      const data_pesagem = BRString2datetimeIso.methods.BRString2datetimeIso(
        i.data
      )
      return {
        ...i,
        data: data_pesagem ? new Date(data_pesagem).toISOString() : null,
        manual: i.manual || true,
      }
    })
    .filter((i) => !i.public_id)

  state.agendamento.numero_documento = '0'
  const agendamento = {
    public_id: state.agendamento.public_id,
    proprietario: state.agendamento.proprietario.public_id,
    gate: state.agendamento.gate.public_id,
    cliente: state.agendamento.cliente.public_id,
    remetente: state.agendamento.remetente?.public_id,
    destinatario: state.agendamento.destinatario?.public_id,
    transportadora: state.agendamento.transportadora.public_id,
    tipo_operacao: state.agendamento.tipo_operacao.id,
    tipo_documento: state.agendamento.tipo_documento,
    terminal: state.agendamento.terminal.public_id,
    tipo_frete: state.agendamento.tipo_frete.id,
    motorista: state.agendamento.motorista.public_id,
    fornecedor: state.agendamento.fornecedor.public_id,
    numero_documento: state.agendamento.numero_documento,
    observacao: state.agendamento.observacao,
    veiculo: state.agendamento.veiculo.id,
    conjunto: state.agendamento.conjunto,
    pedidos: todosOsPedidos,
    janela_carga_descarga: state.agendamento.janela_carga_descarga?.public_id,
    programacao_unidade: state.agendamento.programacao_unidade?.public_id,
    programacao_fornecedor: state.agendamento.programacao_fornecedor?.public_id,
    programacao_regiao: state.agendamento.programacao_regiao?.public_id,
    programacao_cliente: state.agendamento.programacao_cliente?.public_id,
    vaga: state.agendamento.vaga?.public_id,
    rfid: state.agendamento.access_credential,
    access_credential: state.agendamento.access_credential,
    navio: state.agendamento.navio?.item?.id,
    di: state.agendamento.navio?.di,
    pier: state.agendamento.navio?.pier,
    pesagens: [...pesagens],
    extra: cleanJson(state.agendamento.extra) || {},
  }
  agendamento['servicos_agendados'] = state.agendamento.servicos_agendados.map(
    (s) => s.id
  )

  if (
    triagem ||
    state.agendamento.isEdicao ||
    state.agendamento.isEdicaoTriagem
  ) {
    if (state.agendamento.isEdicao) {
      agendamento['servicos_triados'] = []
      agendamento['triado'] = false
    } else {
      agendamento['servicos_triados'] = state.agendamento.servicos_triados.map(
        (s) => ({
          servico_autorizado: state.agendamento.fornecedor.servicos_autorizados.find(
            (i) => i.servico.id == s.servico.id
          )?.public_id,
          forma_pagamento: s.forma_pagamento
            ? s.forma_pagamento.public_id
            : null,
          transportadora: s?.transportadora?.transportadora?.transportadora_id,
          valor: Number(
            Number(s.valorTotal || s.valor_movimentacao || 0).toFixed(2)
          ),
        })
      )
      agendamento['triado'] = true
    }

    try {
      const retorno = await patioApi.editAgendamento(
        agendamento.public_id,
        agendamento
      )
      return Promise.resolve(retorno)
    } catch (error) {
      return Promise.reject(error)
    }
  } else {
    try {
      const retorno = await patioApi.addAgendamento(empresaAtual, agendamento)
      return Promise.resolve(retorno)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
const enviarAnexos = async ({ rootState }, { agendamento, anexos }) => {
  const empresaAtual = rootState.auth.empresaAtual
  try {
    const data = await patioApi.addAgendamentoAnexos(
      empresaAtual,
      agendamento,
      anexos
    )
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getLaudoLimpezaAgendamento = async ({ rootState }, agendamento) => {
  try {
    const empresaAtual = rootState.auth.empresaAtual
    const data = await patioApi.getLaudoLimpezaAgendamento(
      empresaAtual,
      agendamento
    )
    return Promise.resolve(data)
  } catch (e) {
    return Promise.reject(e)
  }
}

export default {
  reset,
  getLaudoLimpezaAgendamento,
  gerarSenhasDaTriagem,
  atualizaTiposFreteAgendamento,
  atualizaFornecedoresAgendamento,
  atualizaClientesAgendamento,
  buscaClientesAgendamento,
  buscaClienteAgendamento,
  atualizaGatesAgendamento,
  atualizaTransportadorasAgendamento,
  atualizaTerminaisAgendamento,
  concatTransportadoras,
  atualizaEstacionamentoAgendamento,
  atualizaSetoresAgendamento,
  atualizaVagasAgendamento,
  updateAtributoAgendamento,
  buscaVeiculo,
  informacoesMotorista,
  enviarAgendamento,
  updateAtributo,
  enviarAnexos,
}
